import { Component, OnInit, ChangeDetectionStrategy, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService } from '../services/auth.service';
import { AlertService } from '../services/alert.service';
import Swal from 'sweetalert2';
import { AtendimentoService } from '../services/atendimento.service';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  role?: string | string[];
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
  callback?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
  role?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [{
  path: '/dashboard',
  title: 'Painel Inicial',
  type: 'link',
  icontype: 'dashboard'
}, {
  path: '/atendimento',
  title: 'Atendimento',
  role: 'Atendimento',
  type: 'sub',
  icontype: 'assignment',
  collapse: 'atendimento',
  children: [
    { path: 'novo-atendimento', title: 'Novo Atendimento', ab: 'NA', role: 'Atendimento' },
    { path: 'pesquisar-atendimento', title: 'Pesquisar Atendimentos', ab: 'PA', role: 'Atendimento' },
    { path: 'meus-atendimentos', title: 'Meus Atendimentos', ab: 'MA', role: 'Tecnico' },
    { path: 'agendamentos', title: 'Agenda de Atendimentos', ab: 'AG', role: 'Tecnico' },
    { path: 'encaminhamentos-pendentes', title: 'Fila de Encaminhentos', ab: 'FE', role: 'Tecnico' },
  ]
}, {
  path: '/individuo',
  title: 'Indivíduo',
  type: 'sub',
  icontype: 'person',
  collapse: 'individuo',
  children: [
    { path: 'pesquisar-individuo', title: 'Pesquisar Indivíduo', ab: 'PI' },
    { path: 'cadastro', title: 'Novo indivíduo', ab: 'NI', role: 'EdicaoIndividuo' },
  ]
}, {
  path: '/grupo-familiar',
  title: 'Grupo Familiar',
  type: 'sub',
  icontype: 'group',
  collapse: 'grupo-familiar',
  children: [
    { path: 'novo-grupo-familiar', title: 'Novo Grupo', ab: 'NG', role: 'InclusaoGrupo' },
    { path: 'pesquisar-grupo', title: 'Pesquisar Grupo', ab: 'PG' },
    { path: 'mapa', title: 'Mapa de domicílios', ab: 'MP', role: 'EdicaoGrupo' },

  ]
}, {
  path: '/plano-acao',
  title: 'Plano de Ação',
  //role: 'Plano de Ação',
  type: 'sub',
  icontype: 'library_add_check',
  collapse: 'plano-acao',
  children: [
    { path: 'novo-plano-acao', title: 'Novo Plano', ab: 'NP', role: 'Atendimento' },
    { path: 'pesquisar-plano-acao', title: 'Pesquisar Plano', ab: 'NP', role: 'Atendimento' },
    // { path: 'listar', title: 'Meus Planos', ab: 'MP', role: 'Atendimento' },
    // { path: 'monitoramento', title: 'Monitoramento de Atividades', ab: 'MA', role: 'Atendimento' }
  ]
}
, {
  path: '/servicos',
  title: 'Serviços',
  //role: 'Plano de Ação',
  type: 'sub',
  icontype: 'library_add_check',
  collapse: 'servicos',
  children: [
    { path: 'listar-servico', title: 'Listar Serviço', ab: 'LS', role: 'Atendimento' },
    // { path: 'listar', title: 'Meus Planos', ab: 'MP', role: 'Atendimento' },
    // { path: 'monitoramento', title: 'Monitoramento de Atividades', ab: 'MA', role: 'Atendimento' }
  ]
}
, {
  callback: 'admin',
  path: '',
  title: 'Ferramentas',
  role: ['Parametrizador'],
  type: 'link',
  icontype: 'summarize'
}];
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewChecked {
  user: any = { name: '' };
  atendimentoCorrente: BehaviorSubject<any>;
  queryParamsAtendimento: { uid: string, chave: string };
  constructor(
    public auth: AuthService,
    public alertService: AlertService,
    public atendimentoService: AtendimentoService,
    private ref: ChangeDetectorRef) {
    this.user = this.auth.getUserTokenDecoded();

    this.atendimentoCorrente = this.atendimentoService.atendimentoCorrente;

  }

  callback(event) {
    switch (event) {
      case 'admin':
        const accessToken = this.auth.getAccessToken();
        const wrapper = $('#formWrapper');
        wrapper.empty();
        var form = $("<form></form>").attr('method', 'POST').attr('action', `${environment.adminBaseUrl}Account/LoginJWT`)
          .append($('<input type="hidden" name="token" enctype="multipart/form-data"/>').val(accessToken))
          .appendTo($('#formWrapper'))
          .submit();


        break;
    }
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  public menuItems: any[];
  ps: any;
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  async ngOnInit() {
    this.queryParamsAtendimento = {
      uid: this.atendimentoService.atendimentoCorrente?.value?.uid,
      chave: this.atendimentoService.atendimentoCorrente?.value?.chaveAtendimento
    };

    // this.menuItems = ROUTES.filter(menuItem => {
    //   return !menuItem.role || this.auth.isInRole(menuItem.role);
    // });
    // (this.menuItems as RouteInfo[]).forEach(c => {
    //   if (c.children) {

    //     const permitedChilds = c.children.filter((d, i) => {
    //       return !d.role || this.auth.isInRole(d.role);
    //     });
    //     c.children = permitedChilds;
    //   }
    // });
    const inRoleMenu = [];
    for (let i = 0; i < ROUTES.length; i++) {
      const c = ROUTES[i];
      console.log('menu isInRole', c);
      const isInRole = await this.auth.isInRole(c.role);
      let included = false;
      environment.debug && console.log('filter menu', c, isInRole);
      if (!c.role || isInRole) {
        inRoleMenu.push(c);
        included = true;
      }
      if (c.children) {
        const permitedChilds = [];
        for (let y = 0; y < c.children.length; y++) {
          const d = c.children[y];
          const childInRole = !d.role || await this.auth.isInRole(d.role);
          if (childInRole) {
            permitedChilds.push(d);
          }
        }
        c.children = permitedChilds;
        if (c.children?.length > 0 && included === false) {
          inRoleMenu.push(c);
        }
      }
    }

    this.menuItems = inRoleMenu;


    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      this.ps = new PerfectScrollbar(elemSidebar);
    }
  }


  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
}
