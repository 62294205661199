import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './services/AuthGuard';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [{
      path: '',
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    }, {
      path: 'individuo',
      loadChildren: () => import('./individuo/individuo.module').then(m => m.IndividuoModule)
    }, {
      path: 'atendimento',
      loadChildren: () => import('./atendimento/atendimento.module').then(m => m.AtendimentoModule)
    }, {
      path: 'grupo-familiar',
      loadChildren: () => import('./grupo-familiar/grupo-familiar.module').then(m => m.GrupoFamiliarModule)
    }, {
      path: 'plano-acao',
      loadChildren: () => import('./plano-acao/plano-acao.module').then(m => m.PlanoAcaoModule)
    },{
      path: 'servicos',
      loadChildren: () => import('./servicos/servicos.module').then(m => m.ServicosModule)
    }, {
      path: 'usuario',
      loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule)
    }]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'auth',
      loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    }]
  }
];
