<mat-form-field class="px-4">
  <div class="input-group no-border">
    <input type="text" matInput #input class="form-control" [placeholder]="placeholder" (blur)="hideInput()"
      [value]="value" (change)="searchOnChange ? doSearch(): propagateChange(input.value)"
      (keydown.enter)="searchOnChange ===false ? doSearch(): propagateChange(input.value)">
    <button mat-icon-button type="button" (click)="doSearch()"
      style="top:20px;left:20px;background-color:white;z-index:1">
      <i class="material-icons">search</i>
      <div class="ripple-container"></div>
    </button>
  </div>

</mat-form-field>
