import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { CadastroIndividuoModel } from './../models/CadastroIndividuoModel';
import { LocalStorageCacheService } from './../shared/local-storage-cache.service';
import { PageEvent } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { Router } from '@angular/router';
import { QuestionarioService } from './questionario.service';
import { ServicosService } from './servicos.service';

const sessionName = 'cadastroIndividuoModel';
@Injectable({
  providedIn: 'root'
})
export class IndividuoService {


  private currentModelInstance: any;
  loading: boolean;
  resultadoPesquisa: any;
  resultadoPesquisaSolicitante: any;

  private emptyModel = {
    dadosPessoais: {},
    documentos: {
      ctps: {},
      filiacao: {},
      cnh: {},
      tituloEleitor: {},
      reservista: {},
      certidoes: [],
      arquivos: [],
    },
    dadosGrupoFamiliar: {},
    contatos: [],
    questionarios: [],
    questionariosGrupoFamiliar: [],
    beneficios: [],
    rendas: [],
    despesas: [],
    ...this.servicosService.servicos,
  };
  updateOnChanges = true;
  limparModelos() {
    sessionStorage.removeItem(sessionName);
    sessionStorage.removeItem(sessionName + 'Updated');
  }

  existeDocumentos(docs, uid = null, useCurrentModel = true) {
    if (!uid && useCurrentModel && this.currentModel.uid) {
      uid = this.currentModel.uid;
    } else
      if (!uid) {
        uid = '';
      }
    return this.api.getByCompleteEndpoint(
      `/individuo/existe-individuo?uid=${uid}&cpf=${docs.cpf}&nis=${docs.nis}` +
      `&rg=${docs.rg ? docs.rg : ''}&uf=${docs.uf ? docs.uf : ''}`, true);
  }
  salvarAtualizacoes(property: any, models: any, displayAlert = true): Promise<any> {
    console.log('salvarAtualizacoes', property, models);
    if (property === 'servicos') {
      //Reconstroi Model de serviços por grupos de serviço
      const modelGrupoServico = JSON.parse(JSON.stringify(models));
      modelGrupoServico.servicos = {};
      Object.keys(this.servicosService.servicos).forEach(grupo => {
        if (!models[grupo + 'Deleted'])
          models[grupo + 'Deleted'] = [];
        if (!models[grupo])
          models[grupo] = [];
        modelGrupoServico.servicos[grupo] = [...models[grupo], ...models[grupo + 'Deleted']];
        console.log('removendo invalidos', modelGrupoServico, grupo);
        const removeInvalidos = modelGrupoServico.servicos[grupo]?.campos?.filter(c => !(/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/.test(c.codigo)));
        if (removeInvalidos && removeInvalidos.length > 0 && removeInvalidos?.forEach) {
          removeInvalidos?.forEach(invalido => {
            delete modelGrupoServico.servicos[grupo].campos[invalido];
          });
        }
        console.log('invalidos', removeInvalidos);


        delete modelGrupoServico[grupo];
      });

      modelGrupoServico.servicos?.grupos?.forEach(element => {
        element.campos = element.campos.filter(c => c.codigo != "grupoSelecionado");
      });
      console.log('model persistido', property, modelGrupoServico);
      return this.savePersistedModel(property, modelGrupoServico, displayAlert);
    }
    /*

    */

    console.log('model persistido - fora if', property, models);
    return this.savePersistedModel(property, models, displayAlert);
  }
  descartarAtualizacoes(prop: any): Promise<any> {
    const model = this.currentModel;
    if (prop === 'servicos') {
      model.gruposServico.forEach(grupo => {
        return this.descartarAtualizacoes(grupo);
      });
    }
    model[prop] = model.modelDatabase[prop] ? model.modelDatabase[prop] : this.emptyModel[prop] || [];
    this.setUpdated(prop, undefined);
    this.currentModel = model;
    return Promise.resolve(prop);
  }
  excluirTodasAtualizacoes() {
    sessionStorage.removeItem(sessionName + 'Updated');
  }

  constructor(
    private api: SettingsService,
    private cache: LocalStorageCacheService,
    private questionarioService: QuestionarioService,
    private servicosService: ServicosService,
    private router: Router) {
    cache.timeout = 30;
  }

  carregarDespesas(uuid: any): Promise<any[]> {
    return this.carregarPropriedadeIndividuo('despesas', uuid, true);
  }
  carregarRendas(uuid: any): Promise<any[]> {
    return this.carregarPropriedadeIndividuo('rendas', uuid, true);
  }
  carregarBeneficios(uuid: any): Promise<any[]> {
    return this.carregarPropriedadeIndividuo('beneficios', uuid, true);
  }
  carregarContatos(uuid: any): Promise<any[]> {
    return this.carregarPropriedadeIndividuo('contatos', uuid, true);
  }
  carregarDocumentos(uuid: any): Promise<any> {
    return this.carregarPropriedadeIndividuo('documentos', uuid);
  }
  carregarDadosPessoais(uuid: any, displayAlert = true): Promise<any> {
    return this.carregarPropriedadeIndividuo('dados-pessoais', uuid, false, displayAlert);
  }
  carregarDadosGrupo(uuid: any): Promise<any> {
    return this.carregarPropriedadeIndividuo('dados-grupo', uuid);
  }
  carregarQuestionarios(uuid: any): Promise<any> {
    return this.carregarPropriedadeIndividuo('questionarios', uuid, true);
  }


  carregarServicos(uuid: any): Promise<any> {
    return this.carregarPropriedadeIndividuo('servicos', uuid, true);
  }

  pesquisarIndividuo(params, pageEvent: PageEvent): Promise<any> {
    this.resultadoPesquisa = null;
    if (pageEvent) {
      params.tamanho = parseInt(pageEvent.pageSize.toString(), 10);
      params.pagina = parseInt((pageEvent.pageIndex + 1).toString(), 10);
    }
    if (environment.debug === true) {
      //console.log('pesquisarIndividuo params:', params);
    }

    return this.api.postJsonEndpoint('/individuo/pesquisar',
      params, true)
      .then((result) => {
        this.resultadoPesquisa = result;
        this.api.configurePaginator(result, pageEvent);
        //if (environment.debug === true) { console.log('pesquisarIndividuo', this.resultadoPesquisa); }
        return result;
      });
  }


  get currentModel(): CadastroIndividuoModel {
    this.currentModelInstance = JSON.parse(sessionStorage.getItem(sessionName));
    if (!this.currentModelInstance) {
      this.currentModelInstance = this.emptyModel;
      this.currentModel = this.currentModelInstance;
    }
    return this.currentModelInstance;
  }

  set currentModel(value: CadastroIndividuoModel) {
    this.currentModelInstance = value;
    if (!this.currentModelInstance) {
      sessionStorage.removeItem(sessionName);
      return;
    }
    sessionStorage.setItem(sessionName, JSON.stringify(this.currentModelInstance));
  }

  setUpdated(property, source) {
    let updated = JSON.parse(sessionStorage.getItem(sessionName + 'Updated'));
    if (!updated) {
      updated = {};
    }
    if (!source) {
      delete updated[property];
    } else {
      updated[property] = source;
    }

    sessionStorage.setItem(sessionName + 'Updated', JSON.stringify(updated));

  }
  isAnyUpdated(): boolean {
    let updated = false;
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key.indexOf('Updated') === -1) {
        updated = this.isUpdated(key);
        if (updated === true)
          return true;
      }
      return false;
    }
  }

  isUpdated(property): boolean {
    const updated = JSON.parse(sessionStorage.getItem(sessionName + 'Updated'));
    return updated !== null && updated !== undefined
      && updated[property] !== null && updated[property] !== undefined;
  }

  public isModelEmpty() {
    this.currentModelInstance = JSON.parse(sessionStorage.getItem(sessionName));
    return this.currentModelInstance === null || this.currentModelInstance === undefined;
  }


  public carregarPropriedadeIndividuo(property, guid, isArray = false, displayAlert = true) {
    return this.api.getByCompleteEndpoint(`/individuo/carregar-${property}/${guid}`, true, displayAlert)
      .then((result) => {
        if (environment.debug === true) {
        }
        return result;
      }).catch(error => {
        if (environment.debug === true) {
        }
        return isArray ? [] : {};
      });
  }

  public updatePersistedModel(property, source, setUpdated = false) {
    const m = this.currentModel;
    if (Array.isArray(source)) {
      m[property] = source.filter(item => item && !item.excluido);
      m[property + 'Deleted'] = source.filter(item => item && item.excluido);
    } else {
      m[property] = source;
    }
    this.currentModel = m;
    if (setUpdated && m && m.modelDatabase[property] && this.updateOnChanges === true) {
      this.setUpdated(property, source);
    }
  }

  public savePersistedModel(property, model: any = null, displayAlert = true) {
    if (!model && model !== null && model !== undefined) {
      model = this.currentModel;
    }
    const propertyUrl = property && property.split ? property.split(/(?=[A-Z])/).join('-').toLowerCase() : property;
    const modelKeys = Object.keys(model);
    let saveModel: any = null;

    const mergeDeletedArray = (_model, _property) => {
      let _saveModel: any = null;
      if (_model && _property) {
        if (Array.isArray(_model[_property]) && Array.isArray(_model[_property + 'Deleted'])) {
          _saveModel = [..._model[_property], ..._model[_property + 'Deleted']];
        } else {
          _saveModel = _model[_property];
        }
        if (typeof (_saveModel) == 'object') {
          var innerProps = Object.keys(_saveModel);
          innerProps.filter(f => f.indexOf('Deleted') == -1 && Array.isArray(_saveModel[f]))
            .forEach(p => {
              _saveModel[p] = mergeDeletedArray(_saveModel, p);
            });
        }
      } else return _model;
      return _saveModel;
    };
    saveModel = mergeDeletedArray(model, property);
    saveModel.uid = model.uid;
    saveModel.gid = model.gid;


    if (property === 'questionarios') {
      return new Promise(async (accept, reject) => {
        const gravacoes = [];
        const erros = [];
        const enviarRoteiros = [];
        saveModel.forEach(roteiro => {
          if (roteiro.questoesResposta && roteiro.questoesResposta.length > 0) {
            const grava = roteiro.questoesResposta.filter(c => c.resposta !== null && c.resposta !== undefined).length > 0;
            if (grava === true) {
              enviarRoteiros.push(roteiro);
            }
          }
        });

        for (let i = 0; i < enviarRoteiros.length; i++) {
          const roteiro = enviarRoteiros[i];
          try {
            const aplicacao = await this.questionarioService.criarAplicacaoIndividuo(roteiro.roteiro, model.uid);
            const gravacao = await this.questionarioService.gravarRoteiroQuestionario(aplicacao.criado, roteiro);
          } catch (e) {
            erros.push(e);
          }
        }
        if (erros.length === 0) {
          this.setUpdated('questionarios', null);
        }
        accept({ gravacoes, erros });
      });
    } else if (property === 'questionariosGrupoFamiliar') {
      return new Promise(async (accept, reject) => {
        const gravacoes = [];
        const erros = [];
        const enviarRoteiros = [];
        saveModel.roteiros.forEach(roteiro => {
          if (roteiro.questoesResposta && roteiro.questoesResposta.length > 0) {
            const grava = roteiro.questoesResposta.filter(c => c.resposta !== null && c.resposta !== undefined).length > 0;
            if (grava === true) {
              enviarRoteiros.push(roteiro);
            }
          }
        });

        for (let i = 0; i < enviarRoteiros.length; i++) {
          const roteiro = enviarRoteiros[i];
          try {
            const aplicacao = await this.questionarioService.criarAplicacaoGrupoFamiliar(roteiro.roteiro, model.gid);
            const gravacao = await this.questionarioService.gravarRoteiroQuestionario(aplicacao.criado, roteiro);
          } catch (e) {
            erros.push(e);
          }
        }
        if (erros.length === 0) {
          this.setUpdated('questionariosGrupoFamiliar', null);
        }
        accept({ gravacoes, erros });
      });
    }
    else if (property === 'endereco') {
      return this.api.postJsonEndpoint(`/grupo-familiar/${model.gid}/novo-${propertyUrl}`,
        saveModel, true, displayAlert)
        .then((result) => {
          if (result) {
            this.setUpdated(property, null);
          }
          return result;
        });
    } else if (property === 'servicos') {
      //Reconstroi Model de serviços por grupos de serviço
      delete saveModel.uid;
      delete saveModel.gid;
      return this.api.postJsonEndpoint(`/individuo/salvar-servicos/${model.uid}`,
        saveModel, true, displayAlert)
        .then((result) => {
          // }
          if (result) {
            this.setUpdated(property, null);
          }
          return result;
        });
    }
    else {
      return this.api.postJsonEndpoint(`/individuo/salvar-${propertyUrl}/${model.uid}`,
        saveModel, true, displayAlert)
        .then((result) => {
          if (result) {
            this.setUpdated(property, null);
          }
          return result;
        });
    }
  }


  createLoader() {
    this.loading = true;
  }

  async previewImagem(uid, files: any, grupo: string = 'foto'): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.createLoader();
      if (files.length === 0) {
        reject('Não foi permitido o acesso ao arquivo selecionado.');
      }
      const mimeType = files[0].type;
      if (grupo == 'foto' && mimeType.match(/image\/*/) == null) {
        reject('Somente imagens são permitidas.');
        return;
      }

      if (mimeType.match(/image|pdf|word|excel|powerpoint\/*/) == null) {
        reject('Somente arquivos de extensões jpg,png,bmp,gif,tiff,doc,docx,xls,xlsx ou pdf são permitidos.');
        return;
      }

      const request = await this.api.configureRequest({
        method: 'POST',
        endpoint: `/individuo/${uid}/enviar-imagem-${grupo}-preview`,
        useAccessToken: true
      });
      request.headers = request.headers.delete('Content-type');
      const formData: FormData = new FormData();
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < files.length; i++) {
        formData.append('img', files[i], files[i].name);
        break;
      }
      request.data = formData;
      return this.api.requestApiService(request).toPromise()
        .then(resolve)
        .finally(() => this.loading = false);
    });
  }

  alertaDocumentosExistentes(docs: { cpf: string; nis: string; rg: string; uf: string; }) {
    this.existeDocumentos(docs).then(existe => {
      if (existe.cpfExists === true) {
        Swal.fire({
          titleText: 'Documento Existente',
          text: `O CPF ${docs.cpf} já existe na base, por favor, antes de prosseguir, verifique o cadastro atual do CPF.`,
          icon: 'warning',
          confirmButtonText: 'Ir para o Cadastro',
          cancelButtonText: 'Errei o número!, vou corrigir...', showCancelButton: true
        }).then(result => {
          if (result.value) {
            const q = docs.cpf;
            this.router.navigate(['/individuo/pesquisar-individuo'],
              { queryParams: { q } });
          }
        });
      }

      if (existe.rgExists === true) {
        Swal.fire({
          titleText: 'Documento Existente',
          text: `O RG ${docs.rg}/${docs.uf} já existe na base, por favor, antes de prosseguir, verifique o cadastro atual no RG.`,
          icon: 'warning',
          confirmButtonText: 'Ir para o Cadastro',
          cancelButtonText: 'Errei o número!, vou corrigir...', showCancelButton: true
        }).then(result => {
          if (result.value) {
            const q = docs.rg;
            this.router.navigate(['/individuo/pesquisar-individuo'],
              { queryParams: { q } });
          }
        });
      }

      if (existe.nisExists === true) {
        Swal.fire({
          titleText: 'Documento Existente',
          text: `O NIS ${docs.nis} já existe na base, por favor, antes de prosseguir, verifique o cadastro atual do NIS.`,
          icon: 'warning',
          confirmButtonText: 'Ir para o Cadastro',
          cancelButtonText: 'Errei o número!, vou corrigir...', showCancelButton: true
        }).then(result => {
          if (result.value) {
            const q = docs.nis;
            this.router.navigate(['/individuo/pesquisar-individuo'],
              { queryParams: { q } });
          }
        });
      }
    });
  }

  entregarBeneficio(uid, uuid) {
    return this.api.postJsonEndpoint(`/individuo/entregar-beneficio/${uid}/${uuid}`, null, true, true);
  }



  async uploadFolhaResumo(uid: any, files: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.createLoader();
      if (files.length === 0) {
        reject('Não foi permitido o acesso ao arquivo selecionado.');
      }
      const mimeType = files[0].type;
      if (mimeType.match(/pdf\/*/) == null) {
        reject('Somente arquivos pdf são permitidos.');
      }

      const request = await this.api.configureRequest({
        method: 'POST',
        endpoint: `/CadastroUnico/importar-folha-resumo`,
        useAccessToken: true
      });
      request.headers = request.headers.delete('Content-type');
      const formData: FormData = new FormData();
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < files.length; i++) {
        if (uid) {
          formData.append('uid', uid);
        }
        formData.append('pdf', files[i], files[i].name);
        break;
      }
      request.data = formData;
      return this.api.requestApiService(request).toPromise()
        .then(resolve)
        .finally(() => this.loading = false);
    });
  }


}
