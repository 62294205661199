export const environment = {
  production: true,
  querySeparator: '?',
  debug: false,
  apiBase: 'https://api.sorocaba.sigaonline.com.br/api',
  apiRoot: 'https://api.sorocaba.sigaonline.com.br/',
  adminBaseUrl: 'https://painel.sorocaba.sigaonline.com.br/',
  signalRBase: 'https://api.sorocaba.sigaonline.com.br/ws',
  apiDomain: 'api.sorocaba.sigaonline.com.br',
  OneSignalAppId: "886f4284-41fa-47df-ab6c-0fe058f626a1",
  centroide: {
    latitude: -23.546668828983528,
    longitude: -47.437885645295516
  },
  municipio: 'Sorocaba',
  ibge: 3552205,
  razaoSocial: 'Prefeitura Municipal de Sorocaba',
  protocoloScheme: 'web+sigaprotocolo',
  idExtensaoIntegradorSiga: "hfejohcmklifcineboaalcnknonlaoff"

};

