<nav class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute" color-on-scroll="500">
  <div class="container">
    <div class="navbar-wrapper">
      <a class="navbar-brand d-none d-sm-none d-md-block" [routerLink]="['/dashboard']">SIGA - Sistema Integrado de Gestão do Atendimento</a>
      <a class="navbar-brand d-block d-sm-block d-md-none" [routerLink]="['/dashboard']">SIGA</a>
    </div>
     
  </div>
</nav>
  <router-outlet></router-outlet>
