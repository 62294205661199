import { NativeDateAdapter } from '@angular/material/core';
import { NgModule } from '@angular/core';
@NgModule({})
export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: any): string {
    let result = date.toDateString();
    const monthName = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    switch (displayFormat) {
      case 'DD/MM/YYYY':
        // Return the format as per your requirement
        result = `${day}/${month}/${year}`;
        break;
      default:
      case 'MMM YYYY':
        // Return the format as per your requirement
        result = `${monthName[date.getMonth()]} - ${year}`;
        break;
      case 'MMM':
        // Return the format as per your requirement
        result = `${monthName[date.getMonth()]}`;
        break;
    }
    return result;
  }
  parse(value: string): any {
    const parts = value.split('/');
    if (parts.length === 3 && parts[2].length === 4) {
      return new Date(+parts[2], (+parts[1]) - 1, +parts[0])
    }
  }
}

export const PT_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthLabel: 'MMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: {year: 'numeric', month: 'numeric', day: 'numeric'},//'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
