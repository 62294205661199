import { Questoes } from './../../../questionario/models/Questoes';
import { environment } from 'src/environments/environment';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { GrupoFamiliarService } from '../../../services/grupo-familiar.service';
import { IndividuoService } from '../../../services/individuo.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-selecao-membro-familiar',
  templateUrl: './selecao-membro-familiar.component.html',
  styleUrls: ['./selecao-membro-familiar.component.scss']
})
export class SelecaoMembroFamiliarComponent implements OnInit {

  @Input()
  model: any = { descricao: 'Selecione', resposta: null };

  @Input()
  viewModel: any;

  @Input()
  gid: string;
  @Input()
  form: FormGroup;

  control: FormControl;
  @Input()
  useAnwserField = true;

  @Output()
  modelUpdated = new EventEmitter<any>();

  subscription: any;

  arrayRespostasBehavior = new BehaviorSubject<any[]>([]);
  arrayRespostas = this.arrayRespostasBehavior.asObservable();


  get placeHolder(): string {
    return this.model?.descricao || `Selecione`;
  }
  constructor(private ref: ChangeDetectorRef, private serviceGrupo: GrupoFamiliarService, private serviceIndividuo: IndividuoService) { }

  ngOnInit(): void {
    if (this.form) {
      console.log('SELECAO MEMBRO FAMILIA - MULTI - MODEL', this.model);
      if (!this.model) {
        this.model = [];
      }
      this.control = new FormControl(this.model && this.model.resposta ?
        Array.isArray(this.model.resposta) ?
          this.model.resposta : [this.model.resposta]
        : null, { updateOn: 'change' });
      this.form.addControl('resposta', this.control);
      this.serviceGrupo.listarSelecaoMembros(this.gid || this.serviceGrupo.currentModel?.gid || this.serviceIndividuo.currentModel?.gid)
        .then(membros => this.arrayRespostasBehavior.next(membros.individuos.map(c => ({ chave: c.uid, descricao: c.nome }))));
      //      this.subscription = this.form.get('resposta').valueChanges.subscribe((val) => {
      //        this.ref.detectChanges();
      //      });
    }
  }

  compareId(c1: any, c2: any): boolean {
    return c1 && c2 ? c1 === c2 : c1 == c2;
  }

}



