import { environment } from 'src/environments/environment';
import { TipoAtendimentoModel } from './../models/TipoAtendimentoModel';
import { BeneficioIndividuoModel } from './../models/BeneficioIndividuoModel';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { LocalStorageCacheService } from '../shared/local-storage-cache.service';
import { TipoContatoModel } from '../shared/contatos/tipo-contato/TipoContatoModel';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DominiosService {

  constructor(private auth: AuthService, private api: SettingsService, private cache: LocalStorageCacheService) {
    cache.timeout = 12 * 60 * 60; // 12 horas
  }

  getUFs() {
    return [{ label: 'Acre', value: 'AC', ibge: 12 }
      , { label: 'Alagoas', value: 'AL', ibge: 27 }
      , { label: 'Amapá', value: 'AP', ibge: 16 }
      , { label: 'Amazonas', value: 'AM', ibge: 13 }
      , { label: 'Bahia', value: 'BA', ibge: 29 }
      , { label: 'Ceará', value: 'CE', ibge: 23 }
      , { label: 'Distrito Federal', value: 'DF', ibge: 53 }
      , { label: 'Espírito Santo', value: 'ES', ibge: 32 }
      , { label: 'Goiás', value: 'GO', ibge: 52 }
      , { label: 'Maranhão', value: 'MA', ibge: 21 }
      , { label: 'Mato Grosso do Sul', value: 'MS', ibge: 50 }
      , { label: 'Mato Grosso', value: 'MT', ibge: 51 }
      , { label: 'Minas Gerais', value: 'MG', ibge: 31 }
      , { label: 'Paraná', value: 'PR', ibge: 41 }
      , { label: 'Paraíba', value: 'PB', ibge: 25 }
      , { label: 'Pará', value: 'PA', ibge: 15 }
      , { label: 'Pernambuco', value: 'PE', ibge: 26 }
      , { label: 'Piauí', value: 'PI', ibge: 22 }
      , { label: 'Rio de Janeiro', value: 'RJ', ibge: 33 }
      , { label: 'Rio Grande do Norte', value: 'RN', ibge: 24 }
      , { label: 'Rio Grande do Sul', value: 'RS', ibge: 43 }
      , { label: 'Rondônia', value: 'RO', ibge: 11 }
      , { label: 'Roraima', value: 'RR', ibge: 14 }
      , { label: 'Santa Catarina', value: 'SC', ibge: 42 }
      , { label: 'Sergipe', value: 'SE', ibge: 28 }
      , { label: 'São Paulo', value: 'SP', ibge: 35 }
      , { label: 'Tocantins', value: 'TO', ibge: 17 }];
  }

  getUFSiglas() {
    return this.getUFs().map((uf) => {
      return { label: uf.value, value: uf.value, ibge: uf.ibge, title: uf.label };
    });
  }

  getDomainType(endpoint: string) {
    return this.cache.getItem
      ('get_' + endpoint.replace(/[\s-.\\\/]/gi, '_'), (arg) => this.api.getByCompleteEndpoint('/dominios/' + endpoint, true));
  }

  getGrupoDominio(grupo: string) {
    return this.cache.getItem(`getGrupoDominio_${grupo}`, (arg) => this.api.getByCompleteEndpoint('/dominios/grupo/tipoComplemento', true));
  }



  getTipoComplemento() {
    return this.getGrupoDominio('tipoComplemento');
  }

  getSubtipoComplemento() {
    return this.getGrupoDominio('subtipoComplemento');
  }

  getAndamentoPrioridades() {
    return this.cache.getItem(`getGrupoDominio_${'andamentoPrioridade'}`, (arg) => this.api.getByCompleteEndpoint('/dominios/grupo/andamentoPrioridade', true));
  }

  getTiposContatos() {
    return this.cache.getItem('getTiposContatos', (arg) => this.api.getByCompleteEndpoint('/dominios/tipo-contato', true));
  }



  getTiposAtendimento() {
    return this.cache.getTypedItem<TipoAtendimentoModel[]>
      ('getTiposAtendimento', (arg) => this.api.getByCompleteEndpoint('/dominios/tipo-atendimento', true))
      .then(r => {
        if (environment.debug === true) { console.log('getTiposAtendimento cache', r); }
        return r;
      });
  }

  getTiposCertidao() {
    return this.getDomainType('tipo-certidao');
  }

  getTiposDespesa() {
    return this.getDomainType('tipo-despesa');
  }

  getTiposRenda() {
    return this.getDomainType('tipo-renda');
  }

  getTiposBeneficiosIndividuo() {
    return this.getDomainType('tipo-beneficio');
  }
  getEquipamentosAssistenciais() {

    return this.getDomainType('equipamentos-assistenciais');
  }
  getTiposSigilos() {
    return this.getDomainType('sigilo');
  }

  getEspecialidadesAtendimento() {
    return this.getDomainType(`especialidades-atendimento/${this.auth.getUserTokenDecoded().perfilAtivo.CodEquipamento}`);
  }

  getEspecialidadesAtendimentoPorTipo(tipoAtendimento) {
    const endpoint = `especialidades-atendimento/${this.auth.getUserTokenDecoded().perfilAtivo.CodEquipamento}/${tipoAtendimento}`;
    console.log('getEspecialidadesAtendimentoPorTipo Endpoint', endpoint);
    return this.getDomainType(endpoint);
  }

  getProfissionaisPorEquipamentoCorrente() {
    const endpoint = `profissionais/${this.auth.getUserTokenDecoded().perfilAtivo.CodEquipamento}`;
    console.log('getProfissionaisPorEquipamentoCorrente Endpoint', endpoint);
    return this.getDomainType(endpoint);
  }
  getProfissionaisPorEquipamentoCorrenteEspecialidade(especialidade) {
    const endpoint = `profissionais/${this.auth.getUserTokenDecoded().perfilAtivo.CodEquipamento}/${especialidade}`;
    console.log('getProfissionaisPorEquipamentoCorrenteEspecialidade Endpoint', endpoint);
    return this.getDomainType(endpoint);
  }

  getTiposServicos(): Promise<any> {
    return this.cache.getItem
      ('getTiposServicos',
        (arg) => this.api.getByCompleteEndpoint('/servicos/tipos', true));
  }

  /**
   * ENUM MySql para informação de sexo do indivíduo
   */
  getSexo() {
    return ['Não Declarado', 'Masculino', 'Feminino'];
  }

  getEscolaridade() {
    return this.getDomainType('escolaridade');
  }

  getEtnia() {
    return this.getDomainType('etnia');
  }

  getReligiao() {
    return this.getDomainType('religiao');
  }

  getEstadoCivil() {
    return this.getDomainType('estado-civil');
  }

  getProfissao() {
    return this.getDomainType('profissao');
  }

  getOcupacao() {
    return this.getDomainType('ocupacao');
  }
  getRaca() {
    return this.getDomainType('raca');
  }

  getParentesco() {
    return this.getDomainType('parentesco');
  }

  getMunicipios() {
    return this.getDomainType('municipios');
  }

  getMunicipiosFiltrado(filtro: string, cacheKey?: string) {
    if (!cacheKey) {
      cacheKey = 'getMunicipiosFiltrado';
    }
    return this.cache.getItem('cacheKey',
      (arg) => new Promise<any>((ok, fail) => {
        this.getMunicipios().then(municipios => {
          const filtrado = municipios.filter(d => {
            const campos = filtro.split(/\, /g);
            campos.forEach((campo: string) => {
              const params = campo.split(/\s{0,}=\s{0,}/g);
              return d[params[0]].toLowerCase().includes(params[1]);
            });
          });
          ok(filtrado);
        })
          .catch(fail);
      }));
  }

  getStatusAtendimento() {
    return this.getDomainType('status-atendimento');
  }
}





