
import { AuthService } from './auth.service';
import { SettingsService } from './settings.service';
import { Injectable, OnDestroy, SecurityContext } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageCacheService } from '../shared/local-storage-cache.service';
import { HttpClient } from '@angular/common/http';
import * as signalR from '@microsoft/signalr';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';

import { timer } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class AlertService implements OnDestroy {
  alerts = new BehaviorSubject<any[]>([]);
  connection: signalR.HubConnection;
  subscribeTimer: Subscription;
  unreadCountBehavior = new BehaviorSubject(0);
  get unreadCount() {
    return this.unreadCountBehavior.asObservable();
  }

  mostrarMensagem(mensagem) {
    Swal.fire({
      title: mensagem.subject,
      icon: 'info',
      html: `<div>Mensagem de: ${mensagem.profile}</div><p>'
        ${mensagem.message}</div>`
    })
      .then((result) => {
        if (mensagem.read !== true) {
          console.log('confirmar Leitura mensagem:', mensagem);
          this.confirmMessageRead(mensagem.id);
        }
      });
  }

  constructor(
    private api: HttpClient,
    private auth: AuthService,
    private domSanitizer: DomSanitizer) {
    const persistedAlerts = localStorage.getItem('alerts') && localStorage.getItem('alerts').startsWith('[') === true ?
      JSON.parse(localStorage.getItem('alerts')) : [];
    this.alerts.next(persistedAlerts);
    const source = timer(30000);
    this.subscribeTimer = source.subscribe(val => this.getUnreadAlerts(val));

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.signalRBase + '/alerts',
        { accessTokenFactory: () => localStorage.access_token })
      .withAutomaticReconnect([0, 2000, 10000, 30000, 60000, 60000, 60000])
      .build();
    this.connection.on('ReceiveBroadcastMessage', (response) => {
      console.log('ReceiveGetUnreadMessages', response);
      this.writeAlertsArray(response);
    });
    this.connection.on('Unauthorized', (response) => {
      console.log('Unauthorized', response);
      Swal.fire({
        title: 'Função não permitida',
        text: response,
        icon: 'warning'
      });
    });


    this.connection.on('ReceiveGetUnreadMessages', (response) => {
      console.log('ReceiveGetUnreadMessages', response);
      this.writeAlertsArray(response);
    });

    this.connection.on('ResponseSetMessageRead', (response) => {
      console.log('ResponseSetMessageRead', response);
      const current = this.alerts.value;
      const message = current.filter(c => c.id === response.id);
      if (message && message.length > 0) {
        message[0].read = response.readAt !== undefined && response.readAt !== null;
        message[0].readAt = response.readAt;
      }
      this.alerts.next(current);
      this.saveOnStorage();
    });

    this.connection.start()
      .then(() => this.getUnreadAlerts(0))
      .catch(err => console.error(err));
    console.log('WebSocket Alerts', this.connection);
  }

  private writeAlertsArray(response: any[]) {
    let current = this.alerts.value;
    response.forEach(message => {
      current = current.filter(c => c.id !== message.id);
      current.push(message);
    });
    this.alerts.next(current);
    this.saveOnStorage();
  }

  private saveOnStorage() {
    localStorage.setItem('alerts', JSON.stringify(this.alerts.value));
  }

  ngOnDestroy() {
    if (this.subscribeTimer) {
      this.subscribeTimer.unsubscribe();
    }
  }

  //SendBroadcastMessage -> ReceiveBroadcastMessage
  sendBroadcastMessage(subject, message, category = null, expireAt = null) {
    this.connection.send('SendBroadcastMessage', subject, message, category, expireAt)
      .then((e) => Swal.fire({
        title: 'Mensagem Enviada',
        text: 'Mensagem enviada com sucesso!'
      }))
      .catch(console.error);
  }

  //GetUnreadMessages -> ReceiveGetUnreadMessages
  getUnreadAlerts(timer = 0) {
    console.log('getUnreadAlerts', timer);
    this.connection.send('GetUnreadMessages')
      .then((e) => console.log('GetUnreadMessages', e))
      .catch(console.error);
  }

  //SetMessageRead -> ResponseSetMessageRead
  confirmMessageRead(messageId) {
    this.connection.send('SetMessageRead', messageId)
      .then((e) => {
        console.log('SetMessageRead', e);
      })
      .catch(console.error);
  }
}
