import { HttpHeaders } from '@angular/common/http';

export interface ICadastroMembroGrupoModel {
  uuid?: string;
  parentescoId?: number;
  parentesco?: string;
  nome?: string;
  nomeSocial?: string;
  apelido?: string;
  nascimento?: Date;
  idade?: string;
  falecimento?: Date;
  estadoCivil?: any;
  naturalidade?: string;
  sexo?: string;
  genero?: string;
  raca?: any;
  etnia?: any;
  pai?: string;
  mae?: string;
  cpf?: string;
  rg?: string;
  excluido?: boolean;
  modificado?: boolean;
  contatos?: any[];
}
export interface IResumoMembrosCadastroGrupoFamiliarModel {
  uuid?: string;
  nome_completo?: string;
  sexo?: string;
  parentesco?: string;
  idade?: string;
}
export interface IResumoFichaTecnicaCadastroGrupoFamiliarModel {
  rendaFamiliar?: number;
  despesas?: number;
  responsavel?: IResumoMembrosCadastroGrupoFamiliarModel;
  responsavel_uuid?: string;
}

export interface IResumoCadastroGrupoFamiliarModel {
  gid?: string;
  membrosGrupo?: IResumoMembrosCadastroGrupoFamiliarModel[];
  fichaTecnicaGrupo?: IResumoFichaTecnicaCadastroGrupoFamiliarModel;
}



export interface ICadastroGrupoFamiliarModel {
  id?: number;
  dadosGrupo?: any;
  guid?: string;
  domicilio?: any;
  totalRenda?: number;
  responsavelUuid?: string;
  membros?: any;
}

export class CadastroGrupoFamiliarModel implements ICadastroGrupoFamiliarModel {
  id?: number;
  dadosGrupo?: any;
  cadUnico?: any;
  guid?: string;
  domicilio?: any;
  responsavelUuid?: string;
  membros?: any;
  gid?: any;//Integração do Cadastro de Individuo e Familia
  grupoFamiliar?: any;//Integração do Cadastro de Individuo e Familia
  constructor() {
  }
}





