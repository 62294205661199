import { Injectable } from '@angular/core';
import { id } from 'date-fns/locale';
import { uriEncode } from '../shared/common';
import { LocalStorageCacheService } from '../shared/local-storage-cache.service';
import { AuthService } from './auth.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class ServicosService {
  async pesquisarServico(search: any): Promise<any[]> {
    return await this.api.postJsonEndpoint<any>('/servicos/pesquisar', search, true);
  }

  public servicos = {
    protecaoBasica: [],
    protecaoEspecial: [],
    programas_projetos: [],
    grupos: [],
  };

  constructor(private auth: AuthService,
    private api: SettingsService,
    private cache: LocalStorageCacheService) {
    cache.timeout = 12 * 60 * 60; // 12 horas
  }

  async getDominioPorCodigo(codigoDominio: string, keyValues: any): Promise<any[]> {
    return await this.api.postJsonEndpoint<any>('/servicos/dominio/' + codigoDominio, {}, true);
  }
  async listarTiposServicoPorCategoria(id: number): Promise<any[]> {
    return await this.api.getByCompleteEndpoint(`/servicos/categorias/${id}/tipos`, true);
  }

  async listar(filtros: any): Promise<IServicoResponse> {
    let parametros = uriEncode(filtros);
    return await this.api.getByCompleteEndpoint(`/servicos?${parametros}`);
  }

  async listarPropriedades(id: number): Promise<any[]> {
    return await this.api.getByCompleteEndpoint(`/servicos/${id}/propriedades`, true);
  }
}
interface IServicoResponse {
  total : number;
  registros : any [];
}
