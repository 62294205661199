import {
  ChangeDetectionStrategy, Component, Directive, ElementRef, EventEmitter,
  Input, OnInit, Output, Renderer2, ViewChild, Pipe, PipeTransform
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DominiosService } from 'src/app/services/dominios.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Guid } from '../../Guid';
import { ArquivoModel } from '../../../models/ArquivoModel';
import { IndividuoService } from 'src/app/services/individuo.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CadastroUnicoService } from '../../../services/cadastro-unico.service';
import * as moment from 'moment';

declare var $: any;
declare var chrome: any;
declare var VMasker: any;


const formataCPF = (cpf) => {
  //retira os caracteres indesejados...
  cpf = cpf.replace(/[^\d]/g, "");
  //realizar a formatação...
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-arquivos',
  templateUrl: './arquivos.component.html',
  styleUrls: ['./arquivos.component.scss'],

})
export class ArquivosComponent implements OnInit {

  @Output()
  modelUpdated = new EventEmitter<any>();
  subscription: any;

  viewerUrl: string;

  public imagePath;
  imgURL: any;
  message: string;

  @ViewChild('imgPreview') imgPreview: ElementRef;

  @Input()
  grupo: "documento" | "arquivo" | "domicilio" | "geral";

  @Input()
  filtrarTipo: string = null;

  @Input()
  tipos: string[] = ["ALVARÁ", "CARTEIRA DE VACINAÇÃO", "CARTÃO SUS", "CERTIDÃO", "CNH", "CPF", "CRACHÁ", "CTPS", "CURRÍCULO", "FOLHA RESUMO", "PASSAPORTE", "PIS", "PROCESSO", "RESERVISTA", "RG", "RNE", "TITULO DE ELEITOR", "OUTRO"];

  viewModel: {
    tipos?: any[],
    displayedColumns?: string[],
    visoes?: any[],
    tipoSelecionado?: string
  };


  form: FormGroup;

  editing: ArquivoModel;

  @Input() model: ArquivoModel[] = [];

  tableSource = new MatTableDataSource<ArquivoModel>([]);

  @ViewChild('table')
  table: any;

  @ViewChild('tipo')
  tipoSelect: any;

  @ViewChild('fileUuid')
  fileUuid: any;

  @ViewChild('fileUrl')
  fileUrl: any;

  @ViewChild('fileBucket')
  fileBucket: any;

  @ViewChild('camera')
  cameraInput: ElementRef;
  currentFile: any;

  @Input()
  acceptMimeTypes = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*";

  get isEmpty(): boolean {
    return this.tableSource.data.filter(d => !d.excluido).length === 0;
  }


  constructor(private dominio: DominiosService,
    private sanitizer: DomSanitizer,
    private cadUnico: CadastroUnicoService,
    private registroService: IndividuoService) {
    this.tableSource.filterPredicate = (data): boolean => {
      return !data.excluido;
    };
    this.viewModel = {
      displayedColumns: ['tipo', 'arquivo', 'visao', 'tempId'],
      visoes: ['COMPLETO', 'FRENTE', 'VERSO', 'PARCIAL'],
      tipos: this.tipos,
      tipoSelecionado: null
    };
    // dominio.getTiposArquivo().then(tipos => {
    //   this.viewModel.tipos = tipos;
    // });
  }

  ngOnDestroy(): void {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }

  dataURItoBlob(dataURI) {
    const imageData = dataURI;
    const byteCharacters = atob(imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: 'image/jpeg'
    });
    return blob;
  }

  public previewCamera() {

    console.log('previewCamera - Elemento Foto', this.cameraInput);
    this.preview(this.cameraInput.nativeElement.files);
  }
  public tirarFoto(event) {
    event.stopPropagation();
    console.log('Tirar Foto', this.cameraInput);
    $(this.cameraInput.nativeElement).click();

    return false;
  }
  preview(files) {
    console.log(files);
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    console.log(mimeType);
    if (mimeType.match(/image|pdf|word|excel\/*/) == null) {
      this.message = 'Somente as extensões listadas são permitidas.';
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    this.registroService.previewImagem(this.registroService.currentModel.uid, files, this.grupo)
      .then(result => {
        this.preencheFormularioArquivo(result);
      }).catch(error => {
        Swal.fire('Erro ao gerar imagem de pré-visualização', error, 'warning');
      });
  }

  private preencheFormularioArquivo(result: any, model = null) {
    if (result?.body?.preview && result?.body?.bucketFile) {

      const currentForm = this.form.value;
      currentForm.arquivo = {
        uuid: result?.body?.temporaryKey || Guid.newGuid(),
        url: result?.body?.preview,
        bucket: result?.body?.bucketFile,
        download: result?.body?.download,
        mime: result?.body?.mime,
      };
      currentForm.observacoes = currentForm.observacoes || model?.observacoes;
      currentForm.visao = currentForm.visao || model?.visao;
      this.form.patchValue(currentForm);
      this.currentFile = currentForm.arquivo;

    }
  }

  getSafeGoogleDocsUrl(url): string {
    this.viewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`;

    return this.viewerUrl;
  }


  ngOnInit(): void {
    this.form = new FormGroup(
      {
        tipo: new FormControl('')
        , uuid: new FormControl('')
        , visao: new FormControl('')
        , observacoes: new FormControl('')
        , arquivo: new FormGroup({
          uuid: new FormControl(''),
          url: new FormControl(''),
          download: new FormControl(''),
          mime: new FormControl(''),
          bucket: new FormControl('')
        }, { updateOn: 'blur' })
      }, { updateOn: 'blur' });

    this.tableSource.data = this.model;
    $(document).ready(() => {
      const c = document.querySelector('.date-mask');
      VMasker(c).unMask();
      VMasker(c).maskPattern('99/99/9999');
    });

  }

  async selecionarTipo(evento) {
    this.viewModel.tipoSelecionado = evento.value;
    if (this.viewModel.tipoSelecionado == "FOLHA RESUMO") {
      Swal.fire({
        titleText: 'Folha Resumo - Cadastro Único',
        text: `Deseja baixar a folha resumo atualizada por meio da integração SIGA -> Cad. Único?`,
        icon: 'question',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não, já tenho o PDF baixado.',
        showCancelButton: true,
      }).then(async result => {
        if (result.value) {
          const buscaFamilia = await this.cadUnico.buscarFamilia(this.registroService.currentModel?.uid, this.registroService.currentModel?.documentos?.cpf);
          console.log('BUSCA FAMILIA RESULT', buscaFamilia)
          if (buscaFamilia.data) {
            console.log('Dados da Familia Selecionada', buscaFamilia.data);
            const folha = await this.cadUnico.baixarFolhaResumo(async (result) => this.cadUnico.uploadFolhaResumo(this.registroService.currentModel?.uid, result.data));
            console.log('RESULTADO FOLHA', folha);
            this.preencheFormularioArquivo(folha, { visao: 'COMPLETO', observacoes: `Folha Resumo Cad. Único - ${moment(new Date()).format('DD/MM/YYYY')}` });
          }
        }

      });
    }
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  salvarArquivo() {
    if (!this.form.valid) {
      return;
    }
    const item = this.form.value;
    item.tipo = this.viewModel.tipoSelecionado;
    item.modificado = true;
    if (!item.uuid || item.uuid.length === 0) {
      item.uuid = Guid.newGuid();
    }
    if (this.editing) {
      Object.keys(this.form.value).forEach(key => {
        this.editing[key] = this.form.value[key];
      });
      this.editing.modificado = true;
      this.editing = null;
      this.model = this.tableSource.data;

    } else {
      if (environment.debug === true) { console.log('arquivo nova', item); }
      this.tableSource.data.push(item);
      this.model = this.tableSource.data;
    }
    this.form.reset();
    this.modelUpdated.emit(this.tableSource.data);
    this.currentFile = null;
    this.table.renderRows();
  }

  editarArquivo(arquivo: ArquivoModel) {
    this.editing = arquivo;
    this.currentFile = arquivo.arquivo;
    console.log('editarArquivo', this.currentFile);
    const clone = JSON.parse(JSON.stringify(arquivo));
    if (clone.id) {
      delete clone['id'];
    }
    this.form.patchValue(clone);
    this.tipoSelect.options.filter(c => c == clone.tipo).pop().select();
  }

  excluirArquivo(arquivo) {
    Swal.fire({
      titleText: 'Confirma Exclusão?',
      text: `Deseja realmente excluir a certidão: ${arquivo.tipo} - ${arquivo.numero}`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
    }).then(result => {
      if (result.value) {
        this.editing = null;
        arquivo.excluido = true;
        this.model = this.tableSource.data;
        if (environment.debug === true) { console.log(this.tableSource.data); }
        this.tableSource.filter = 'excluido';
        this.form.reset();
        this.modelUpdated.emit(this.tableSource.data);
        this.tableSource.filter = Guid.newGuid();
        this.table.renderRows();
      }
    });
  }
}
