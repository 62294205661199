import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.console.log = () => { };
  window.console.warn = () => { };
  window.console.error = () => { };
  window.console.dir = () => { };
  window.console.time = () => { };
  window.console.timeEnd = () => { };
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
/*
 =========================================================
 * Material Dashboard PRO Angular - v2.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-angular2
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
