import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { ResultadoPaginado, ResultadoPesquisaAtendimentoModel } from '../models/PesquisarAtendimento.model';

@Injectable({
  providedIn: 'root'
})
export class AtendimentoService {
  private atendimentoCorrenteSubject = new BehaviorSubject<any>(null);
  private isRequesting = { verificaAtendimentoPendente: false };
  get atendimentoCorrente(): BehaviorSubject<any> {
    const local = sessionStorage.getItem('atendimentoCorrente');
    if (local && local !== null && local !== 'null' && local !== undefined) {
      this.atendimentoCorrenteSubject.next(JSON.parse(local));
    }
    if (this.atendimentoCorrenteSubject?.value === null) {
      // alert(1);
      this.verificaAtendimentoPendente();
    }
    return this.atendimentoCorrenteSubject;
  }
  public abandonarRegistro(route = 'dashboard') {
    this.verificaAtendimentoPendente().then(pendente => {
      this.abandonarAtendimentoPendente().then(r => {
        console.log('Atendimento Cancelado', r);
      });
    })
      .finally(() => {
        this.router.navigate([route], {});
      });
  }
  promisesVerificaAtendimentoPendente: any[] = [];

  public verificaAtendimentoPendente() {
    const ret = new Promise<any>((accept, reject) => {
      if (this.isRequesting.verificaAtendimentoPendente !== true) {

        this.isRequesting.verificaAtendimentoPendente = true;
        return this.api.getByCompleteEndpoint('/atendimento/pendente',
          true)
          .then(atendimento => {
            this.isRequesting.verificaAtendimentoPendente = false;
            this.atualizaAtendimento(atendimento.ficha);
            accept(atendimento);
            let othersCalls: any = null;
            while ((othersCalls = this.promisesVerificaAtendimentoPendente.pop())) {
              othersCalls.accept(atendimento);
            }
          })
          .catch(error => {
            reject(error);
            let othersCalls: any = null;
            while ((othersCalls = this.promisesVerificaAtendimentoPendente.pop())) {
              othersCalls.reject(error);
            }
          });
      } else {
        this.promisesVerificaAtendimentoPendente.push({ accept, reject });
      }
    });
    return ret;
  }
  public abandonarAtendimentoPendente() {
    return this.api.postJsonEndpoint('/atendimento/abandonar-pendente', null,
      true).then(resultado => {
        this.atualizaAtendimento(null);
        return resultado;
      });
  }


  public atualizaAtendimento(atendimento) {
    sessionStorage.removeItem('atendimentoCorrente');
    this.atendimentoCorrenteSubject.next(atendimento);
    console.log('Atualizando sessionStorage de Atendimento', atendimento);
    if (atendimento && atendimento !== null && atendimento !== undefined && atendimento !== 'null') {
      sessionStorage.setItem('atendimentoCorrente', JSON.stringify(atendimento));
    }
  }

  /**
   * Inicia um novo atendimento para o requerente/solicitante informado.
   * Em caso de já existir um atendimento em aberto no perfil do usuário, será rejeitado o pedido,
   * e retornado no erro, o atendimento em aberto, para ser finalizado ou continuado.
   * @param uid chave do individuo solicitante
   */
  public iniciarAtendimento(uid): Promise<any> {
    if (this.atendimentoCorrente.value &&
      this.atendimentoCorrente.value.statusId === 1) {
      return Promise.reject(this.atendimentoCorrente.value);
    }
    return this.api.postJsonEndpoint(`/atendimento/${uid}/novo`,
      null, true).then(atendimento => {
        this.atualizaAtendimento(atendimento);
        return atendimento;
      });
  }

  constructor(private api: SettingsService, private router: Router) {
    // this.subscription = this.atendimentoCorrenteSubject.subscribe(this.atualizaAtendimento, (error) => { });
    this.atualizaAtendimento(JSON.parse(sessionStorage.getItem('atendimentoCorrente')));
    if (navigator?.registerProtocolHandler) {
      navigator.registerProtocolHandler(
        environment.protocoloScheme, `${document.location.origin}/atendimento/protocolo?qr=%s`
      );
    }
  }

  pesquisarAtendimentos(filtro): Promise<ResultadoPaginado<ResultadoPesquisaAtendimentoModel>> {
    if (filtro && filtro.pageEvent) {
      filtro.tamanho = parseInt(filtro.pageEvent.pageSize.toString(), 10);
      filtro.pagina = parseInt((filtro.pageEvent.pageIndex + 1).toString(), 10);
    }
    return this.api.postJsonEndpoint<ResultadoPaginado<ResultadoPesquisaAtendimentoModel>>('/atendimento/pesquisar',
      filtro, true);
  }
  detalharAtendimento(uid, chaveAtendimento): Promise<any> {
    if (chaveAtendimento) {
      return this.api.getByCompleteEndpoint(`/atendimento/${uid}/${chaveAtendimento}`, true);
    } else {
      return Promise.resolve(null);
    }
  }
  meusAtendimentos(params): Promise<any> {
    return this.api.postJsonEndpoint('/atendimento/meus-atendimentos',
      { consulta: params.consulta }, true);
  }

  registrarAtendimentoGeral(observacao, tipoId, statusId, parecerTecnico): Promise<any> {
    return this.registrarAtendimentoEspecializado('atendimento', observacao, tipoId, statusId, parecerTecnico, null);
  }

  registrarAgendamento(observacao, tipoId, statusId, parecerTecnico, registroEspecializado): Promise<any> {
    return this.registrarAtendimentoEspecializado('agendamento', observacao, tipoId, statusId, parecerTecnico, registroEspecializado);
  }

  registrarEncaminhamento(observacao, tipoId, statusId, parecerTecnico, registroEspecializado): Promise<any> {
    return this.registrarAtendimentoEspecializado('encaminhamento', observacao, tipoId, statusId, parecerTecnico, registroEspecializado);
  }

  registrarConcessaoBeneficio(observacao, tipoId, statusId, parecerTecnico, registroEspecializado): Promise<any> {
    return this.registrarAtendimentoEspecializado('beneficio', observacao, tipoId, statusId, parecerTecnico, registroEspecializado);
  }

  registrarConcessaoServico(observacao, tipoId, statusId, parecerTecnico, registroEspecializado): Promise<any> {
    return this.registrarAtendimentoEspecializado('servico', observacao, tipoId, statusId, parecerTecnico, registroEspecializado);
  }

  registrarAtendimentoEspecializado(especializacao, observacao, tipoId, statusId, parecerTecnico, registroEspecializado): Promise<any> {
    return new Promise<any>((accept, reject) => {
      if (this.atendimentoCorrente.value) {
        const id = this.atendimentoCorrente.value.chaveAtendimento;
        const uid = this.atendimentoCorrente.value.uid;
        return this.api.postJsonEndpoint(`/atendimento/${uid}/${id}/registro-${especializacao}`,
          { observacao, tipoId, statusId, parecerTecnico, registroEspecializado }, true, true)
          .then(registro => {
            if (statusId !== 6) {
              return this.api.getByCompleteEndpoint(`/atendimento/${uid}/${id}`)
                .then(atendimento => {
                  this.atualizaAtendimento(atendimento.ficha);
                  accept(this.atendimentoCorrente.getValue());
                });
            } else {
              this.atualizaAtendimento(null);
              accept(this.atendimentoCorrente.getValue());
            }
          })
          .catch(error => reject(error));
      } else {
        return Promise.resolve(null);
      }
    });
  }

  modificarRegistroAtendimento(model, tipo = 'atendimento') {
    // {uid}/{id}/{farid}/registro-parecer
    return new Promise<any>((accept, reject) => {
      if (this.atendimentoCorrente.value || model.chaveAtendimento) {
        const id = model.chaveAtendimento ? model.chaveAtendimento : this.atendimentoCorrente.value.chaveAtendimento;
        const uid = model.chaveAtendimento ? model.uid : this.atendimentoCorrente.value.uid;
        const farid = model.chaveAtendimento ? model.farid : this.atendimentoCorrente.value.farid;

        return this.api.postJsonEndpoint(
          `/atendimento/${uid}/${id}/${farid}/registro-${tipo}`,
          model,
          true,
          true)
          .then(registro => {
            accept(registro);
          })
          .catch(error => reject(error));
      } else {
        return Promise.resolve(null);
      }
    });
  }

}
