import { DisableControlDirective } from './shared/directives';
import { LocalStorageCacheService } from './shared/local-storage-cache.service';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { APP_BASE_HREF, CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { AuthGuard } from "./services/AuthGuard";
import { SettingsService } from './services/settings.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoaderComponent } from './shared/loader/loader.component';
import { AuthInterceptor } from './auth/auth.interceptor';

import localePt from '@angular/common/locales/pt';
import { OsmViewComponent } from './shared/osm-view/osm-view.component';

import { NgxViacepModule } from '@brunoc/ngx-viacep'; // Importando o módulo

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BrowserModule } from '@angular/platform-browser';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedComponentsModule } from './shared/componentes.module';
import { MaterialModule } from './material.module';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrModule } from 'ngx-toastr';

registerLocaleData(localePt, 'pt-BR');

export function tokenGetter() {
  return localStorage.getItem("access_token");
}


@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),  
    FormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy'
    }),
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    QRCodeModule,
    SharedComponentsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [environment.apiDomain],
        disallowedRoutes: [
          environment.apiBase + '/login',
          environment.apiBase + '/login/esqueci-senha'
        ]
      }
    }),
    ReactiveFormsModule,
    NgxViacepModule,
    SweetAlert2Module.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    DisableControlDirective
  ],
  providers: [AuthGuard, SettingsService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    LocalStorageCacheService,
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
