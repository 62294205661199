import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const params = this.router.getCurrentNavigation();//.finalUrl.queryParams;
    //console.log('QUERY PARAMS', params);
    // if (params.changePassToken) {
    //   alert(params.changePassToken)
    //   this.authService.jwt.setToken(params.changePassToken);
    // }

    if (await this.authService.isInRole('ForgotPassword') === true) {
      this.router.navigate(['auth/recuperar-senha'], { queryParams: { returnUrl: state.url } });

    } else if (!this.authService.jwt.loggedIn) {
      this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
    }
    return true;
  }
}
