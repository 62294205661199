import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PageEvent, MatPaginatorIntl } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { GrupoFamiliarService } from 'src/app/services/grupo-familiar.service';
import { IndividuoService } from 'src/app/services/individuo.service';
import { AtendimentoService } from '../../services/atendimento.service';
import { DominiosService } from '../../services/dominios.service';
import { ResultadoPaginado, ResultadoPesquisaAtendimentoModel } from '../../models/PesquisarAtendimento.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Guid } from '../../shared/Guid';
import Swal from 'sweetalert2';

const storageKeyFiltros = 'filtrosSalvos-PesquisarAtendimento';
@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-pesquisar-atendimento',
  templateUrl: './pesquisar-atendimento.component.html',
  styleUrls: ['./pesquisar-atendimento.component.scss']
})
export class PesquisarAtendimentoComponent implements OnInit {

  @Input()
  mostrarFiltrosPesquisa = true;

  @Input()
  mostrarDadosIndividuo = true;

  @Input()
  pesquisarAoCarregar = false;

  pesquisaCarregadaBehavior = new BehaviorSubject(false);
  pesquisaCarregada = this.pesquisaCarregadaBehavior.asObservable();

  public seriesIndexCss = 'abcdefghijlmnop';
  public tiposAtendimentoDistintosMap = {};
  novoStatus = [
    { id: 2, descricao: 'Em Atendimento' },
    { id: 3, descricao: 'Encaminhado' },
    { id: 4, descricao: 'Agendado' },
    { id: 5, descricao: 'Não comparecimento' },
    { id: 6, descricao: 'Finalizado' }
  ];
  pageSizeOptions: number[] = [10, 50, 100];
  sortOptions = [
    { title: 'Padrão', direction: 1, field: 0 }, // id
    { title: 'Registro mais recente', direction: 1, field: 1 },  // ultimoRegistro
    { title: 'Data de Abertura (Crescente)', direction: 0, field: 5 }, // abertura
    { title: 'Data de Abertura  (Decrescente)', direction: 1, field: 5 }, // abertura
    { title: 'Nome de Solicitante (Crescente)', direction: 0, field: 2 }, // nome
    { title: 'Nome de Solicitante (Decrescente)', direction: 1, field: 2 }, // nome
    { title: 'Tipo de Atendimento (Crescente)', direction: 0, field: 4 }, // tiposAtendimento
    { title: 'Tipo de Atendimento (Decrescente)', direction: 1, field: 4 }, // tiposAtendimento
    { title: 'Status do Andamento (Crescente)', direction: 0, field: 3 }, // tiposAtendimento
    { title: 'Status do Andamento (Decrescente)', direction: 1, field: 3 }, // tiposAtendimento

  ];
  // MatPaginator Output
  pageEvent: PageEvent = new PageEvent();
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @Input()
  filtros: {
    consulta?: string;
    tipos?: any[];
    status?: any[];
    gid?: any;
    uid?: any;
    profissionais?: any[];
    profissionaisAgendamento?: any[];
    equipamentos?: any[];
    equipamentosEncaminhado?: any[];
    de?: any;
    ate?: any;
    chaveAtendimento?: any;
    sort?: { title: string, direction: number, field: number };
  } = {};
  sigilos: any[];

  formNovoParecer = new FormGroup({
    status: new FormControl(2,{updateOn: 'blur'}),
    parecer: new FormControl('',{updateOn: 'blur'}),
    sigilo: new FormControl(2,{updateOn: 'blur'}),
    observacao: new FormControl('',{updateOn: 'blur'}),
  });

  @ViewChild('panelFiltros') panelFiltros: MatExpansionPanel;

  equipamentosBehavior = new BehaviorSubject<any>(null);
  statusBehavior = new BehaviorSubject<any>(null);
  tiposAtendimentosBehavior = new BehaviorSubject<any>(null);
  resultadosBehavior = new BehaviorSubject<ResultadoPaginado<ResultadoPesquisaAtendimentoModel>>(null);
  profissionaisBehavior = new BehaviorSubject<any>(null);
  equipamentos = this.equipamentosBehavior.asObservable();
  profissionais = this.profissionaisBehavior.asObservable();
  status = this.statusBehavior.asObservable();
  tiposAtendimento = this.tiposAtendimentosBehavior.asObservable();
  resultadoPesquisaAtendimento = this.resultadosBehavior.asObservable();

  form: FormGroup;
  get filtrosSalvos(): any[] {
    return localStorage.getItem(storageKeyFiltros) !== null ? JSON.parse(localStorage.getItem('filtrosSalvos-PesquisarAtendimento')) : [];
  }

  constructor(
    public route: Router,
    public atendimentoService: AtendimentoService,
    public grupoService: GrupoFamiliarService,
    public individuoService: IndividuoService,
    public dominio: DominiosService,
    paginatorIntl: MatPaginatorIntl) {
    const params = this.route?.getCurrentNavigation()?.finalUrl?.queryParams || {};

    this.filtros.consulta = params.q || this.filtros.consulta || '';
    this.filtros.de = params.de || this.filtros.de || null;
    this.filtros.ate = params.ate || this.filtros.ate || null;
    this.filtros.gid = params.gid || this.filtros.gid || null;
    this.filtros.uid = params.uid || this.filtros.uid || null;
    this.filtros.chaveAtendimento = params.chave || this.filtros.chaveAtendimento || null;
    this.filtros.tipos = params.tp ? params.tp.split(',') : this.filtros.tipos || null;
    this.filtros.status = params.st ? params.st.split(',') : this.filtros.status || null;
    this.filtros.profissionais = params.pf ? params.pf.split(',') : this.filtros.profissionais || null;
    this.filtros.profissionaisAgendamento = params.pfag ? params.pfag.split(',') : this.filtros.profissionaisAgendamento || null;
    this.filtros.equipamentos = params.eq ? params.eq.split(',') : this.filtros.equipamentos || null;
    this.filtros.equipamentosEncaminhado = params.eqe ? params.eqe.split(',') : this.filtros.equipamentosEncaminhado || null;
    const sortOption = this.sortOptions.filter(c => c.direction == params.sd && c.field == params.sf);
    this.filtros.sort = sortOption && sortOption.length > 0 ? sortOption[0] : this.sortOptions[0];
    this.pageEvent.pageSize = params.ps || 10;
    this.pageEvent.pageIndex = params.pi || 0;
    paginatorIntl.itemsPerPageLabel = 'registros por página';
    this.form = new FormGroup({
      chaveAtendimento: new FormControl(this.filtros.chaveAtendimento, {updateOn:'blur'}),
      gid: new FormControl(this.filtros.gid, {updateOn:'blur'}),
      uid: new FormControl(this.filtros.uid, {updateOn:'blur'}),
      consulta: new FormControl(this.filtros.consulta, {updateOn:'blur'}),
      equipamentos: new FormControl(this.filtros.equipamentos, {updateOn:'blur'}),
      equipamentosEncaminhado: new FormControl(this.filtros.equipamentosEncaminhado, {updateOn:'blur'}),
      status: new FormControl(this.filtros.status, {updateOn:'blur'}),
      tipos: new FormControl(this.filtros.tipos, {updateOn:'blur'}),
      profissionais: new FormControl(this.filtros.profissionais, {updateOn:'blur'}),
      profissionaisAgendamento: new FormControl(this.filtros.profissionaisAgendamento, {updateOn:'blur'}),
      de: new FormControl(this.filtros.de, {updateOn:'blur'}),
      ate: new FormControl(this.filtros.ate, {updateOn:'blur'}),
      sort: new FormControl(this.filtros.sort, {updateOn:'blur'})
    });


  }
  salvarFiltroPesquisa(nome) {
    console.log('salvarFiltroPesquisa', nome);
    let storageAtual = this.filtrosSalvos;
    if (!storageAtual || storageAtual === null) {
      storageAtual = [];
    }
    localStorage.setItem(storageKeyFiltros,
      JSON.stringify([...storageAtual, {
        id: Guid.newGuid(),
        descricao: nome,
        parametros: this.form.value
      }]));
  }
  excluirFiltroPesquisa(filtro) {
    Swal.fire({

      title: 'Excluir Filtro de Pesquisa',
      text: 'Confirma a exclusão do filtro selecionado?',
      confirmButtonText: 'Sim, excluir',
      confirmButtonColor: 'red',
      cancelButtonText: 'Não',
      showCancelButton: true,
      icon: 'question'
    }).then(result => {
      if (result && result.isConfirmed === true) {
        console.log('excluirFiltroPesquisa', filtro);
        let storageAtual = this.filtrosSalvos;
        if (!storageAtual || storageAtual === null) {
          storageAtual = [];
        }
        storageAtual = storageAtual.filter(c => c.id !== filtro.id);
        localStorage.setItem(storageKeyFiltros, JSON.stringify(storageAtual));
      }
    });
  }

  atualizarFiltroPesquisa(filtro) {

    Swal.fire({
      title: 'Atualizar Filtro de Pesquisa',
      text: 'Redefina (se necessário) um nome para o filtro.',
      input: 'text',
      inputValue: filtro.descricao
    }).then(result => {
      if (result.value) {
        console.log('atualizarFiltroPesquisa', filtro, result);
        let storageAtual = this.filtrosSalvos;
        if (!storageAtual || storageAtual === null) {
          storageAtual = [];
        }
        const item = storageAtual.filter(c => c.id === filtro.id);
        if (item && item.length > 0) {
          item[0].descricao = result.value;
          item[0].parametros = this.form.value;
          localStorage.setItem(storageKeyFiltros, JSON.stringify(storageAtual));
        }
      }
    });

  }

  carregarFiltros(filtro: any = null) {
    console.log('carregarFiltros', filtro);
    this.form.patchValue(filtro);
  }

  pesquisarAtendimento(page: PageEvent = null) {
    if (!page) {
      this.pageEvent.pageIndex = 0;
    } else {
      this.pageEvent.pageIndex = page.pageIndex;
      this.pageEvent.pageSize = page.pageSize;
    }
    console.log('Filtros form', this.filtros, this.form.value);
    this.filtros.consulta = this.form.value.consulta;
    this.filtros.de = this.form.value.de;
    this.filtros.ate = this.form.value.ate;
    this.filtros.tipos = this.form.value.tipos ? Array.isArray(this.form.value.tipos) ?
      this.form.value.tipos.filter(c => !Number.isNaN(c)) : [this.form.value.tipos] : null;

    this.filtros.status = this.form.value.status ? Array.isArray(this.form.value.status) ?
      this.form.value.status.filter(c => !Number.isNaN(c)) : [this.form.value.status] : null;

    this.filtros.profissionais = this.form.value.profissionais ? Array.isArray(this.form.value.profissionais) ?
      this.form.value.profissionais.filter(c => !Number.isNaN(c)) :
      [this.form.value.profissionais] : null;

    this.filtros.profissionaisAgendamento =
      this.form.value.profissionaisAgendamento ? Array.isArray(this.form.value.profissionaisAgendamento) ?
        this.form.value.profissionaisAgendamento
          .filter(c => !Number.isNaN(c)) : [this.form.value.profissionaisAgendamento.id] : null;

    this.filtros.equipamentos = this.form.value.equipamentos ? Array.isArray(this.form.value.equipamentos) ?
      this.form.value.equipamentos.filter(c => !Number.isNaN(c)).map(c => c) : [this.form.value.equipamentos] : null;

    this.filtros.equipamentosEncaminhado =
      this.form.value.equipamentosEncaminhado ? Array.isArray(this.form.value.equipamentosEncaminhado) ?
        this.form.value.equipamentosEncaminhado
          .filter(c => !Number.isNaN(c)).map(c => c) : [this.form.value.equipamentosEncaminhado] : null;
    this.filtros.sort = this.form.value.sort;
    console.log('Filtros model', this.filtros);
    const search = { ...this.filtros, pageEvent: this.pageEvent };
    this.loading.next(true);
    this.atendimentoService.pesquisarAtendimentos(search).then(resultados => {
      this.pageEvent.pageIndex = resultados.model.pagina - 1;
      this.pageEvent.pageSize = resultados.model.tamanho;
      this.pageEvent.length = resultados.model.registros;
      console.log('Resultado Pesquisa', resultados);
      this.panelFiltros.expanded = resultados.model.registros === 0;
      this.resultadosBehavior.next(resultados);
      if (this.pageEvent.length === 1) {
        this.abrirAtendimentoDetalhado(resultados[0]);
      }
    }).finally(() => {
      this.pesquisaCarregadaBehavior.next(true);
      this.loading.next(false);
    });

  }

  ngOnInit(): void {
    this.loading.next(true);
    const dominios = [
      this.dominio.getEquipamentosAssistenciais().then(itens => {
        this.equipamentosBehavior.next(itens);
      }),
      this.dominio.getTiposAtendimento().then(itens => {
        let listTipos = [];
        itens.map(c => {
          listTipos = [...listTipos, ...c.subTipos.map(d => {
            return { id: d.id, descricao: `${d.grupo} » ${d.descricao}` };
          })];
        });
        console.log('getTiposAtendimento', listTipos);
        this.tiposAtendimentosBehavior.next(listTipos);
      }),
      this.dominio.getProfissionaisPorEquipamentoCorrente().then(itens => {
        this.profissionaisBehavior
          .next(itens.filter(c => c.padrao === true)
            .map(c => ({ id: c.chaveUsuario, descricao: `${c.descricao}  »  ${c.nome}` })));
      })];

    this.dominio.getTiposSigilos().then(sigilos => {
      this.sigilos = sigilos;
    });
    Promise.all(dominios).finally(() => {
      this.loading.next(false);
      console.log('Pesquisar Atendimento - Domínios Carregados', dominios);
      if (this.filtros.chaveAtendimento || this.pesquisarAoCarregar === true) {
        this.pesquisarAtendimento();
      }
    });
  }

  compareByIdProperty = (c1: any, c2: any): boolean => {
    const compare = c1 && c2 ? c1 === c2 : c1 == c2;
    console.log('compareByIdProperty result', c1, c2, compare);
    return compare;
  }

  get desabilitaEquipamentos() {
    let disabled = this.form.value !== undefined && this.form.value !== null
      && this.form.value?.tipos !== null && this.form.value?.tipos?.filter !== null;

    if (disabled !== true) {
      return true;
    }
    disabled = this.form.value.tipos.filter(c => c.descricao?.indexOf('Encaminhamento') > -1).length === 0;
    return disabled;
  }

  get desabilitaProfissionalAgendamento() {
    let disabled = this.form.value !== undefined && this.form.value !== null
      && this.form.value?.tipos !== null && this.form.value?.tipos?.filter !== null;

    if (disabled !== true) {
      return true;
    }
    disabled = this.form.value.tipos.filter(c => c.descricao?.indexOf('Agendamento') > -1).length === 0;
    return disabled;
  }

  abrirAtendimentoDetalhado(atendimento) {
    if (atendimento.detalhado) {
      delete atendimento.detalhado;
    }
    this.loading.next(true);
    this.atendimentoService.detalharAtendimento(atendimento.uid, atendimento.chaveAtendimento)
      .then(detalhado => {
        atendimento.detalhado = detalhado;
      }).finally(() => this.loading.next(false));
  }

  beneficiosDescricao(beneficios) {
    return beneficios?.map(c => c.tipo)?.join(',');
  }


  selecionaSigilo(option, parecer) {
    console.log('seleciona Sigilo', option);
  }

  abrirProtocoloAtendimento(atendimento) {
    this.atendimentoService.atualizaAtendimento(null);
    this.route.navigate(['atendimento/protocolo'], {
      queryParams: {
        uid: atendimento.uid,
        chave: atendimento.chaveAtendimento
      }
    });
  }

  modificarRegistroAtendimento(parecer, ficha, registro) {
    if (parecer === true) {
      const modificarAtendimento = this.formNovoParecer.value;
      console.log(modificarAtendimento);

      const model = {
        parecerTecnico: {
          parecer: modificarAtendimento.parecer,
          nivelSigiloId: parseInt(modificarAtendimento.sigilo)
        },
        statusId: parseInt(modificarAtendimento.status),
        observacao: modificarAtendimento.observacao,
        chaveAtendimento: ficha.chaveAtendimento,
        uid: ficha.uid,
        farid: registro
      };
      console.log(parecer, model);
      this.atendimentoService.modificarRegistroAtendimento(model, 'atendimento').then((resultado) => {
        console.log(resultado);
      });
    }
  }


  novoRegistro(ficha) {
    this.route.navigate(['atendimento/novo-atendimento'], {
      queryParams: {
        uid: ficha.uid,
        chave: ficha.chaveAtendimento
      }
    });
  }

  stringify(obj) {
    return JSON.stringify(obj);
  }

  tiposAtendimentoDistintos(resultados: any[]): any[] {
    const tiposAtendimento = {};
    resultados.forEach(item => {
      const tiposAtendimentoItem = item.tiposAtendimento.split(',');
      tiposAtendimentoItem.forEach(function (r, a) {
        const grupo = r.split('|')[0];
        tiposAtendimento[grupo] = tiposAtendimento[grupo] ? ++tiposAtendimento[grupo] : 1;
      });
    });
    let i = 0;
    const ret = Object
      .keys(tiposAtendimento)
      .map(c => ({ tipo: c, count: tiposAtendimento[c].length, cssIndex: i++ }));

    this.tiposAtendimentoDistintosMap = {};
    ret.forEach(c => {
      this.tiposAtendimentoDistintosMap[c.tipo] = c;
    });
    return ret;

  }
}
