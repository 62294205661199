import { ServicoLoasEntityModel } from './../models/ServicoLoasModels';
import { Type } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Guid } from './Guid';
import { ValorServicoEntityModel } from '../models/ServicoLoasModels';


export interface ACLViewModel {
  roles: string[],
  group: string,
  code: string,
  value: string,
  groupName: string,
  form?: FormGroup
  meta?: any
}

export interface CustomPanel {
  title: string,
  modelProperty: string,
  tabs?: ACLViewModel[]
}

export interface ConstructServicoLoasViewModel {
  tipoServico?: ACLViewModel,
  formValues?: any,
  uuid?: string,
  grupoSelecionado?: string,
  sigilo?: any,
  viewModel?: any,
  baseModel?: any
}
export class ServicoLoasViewModel {
  uuid?: string;
  grupoSelecionado?: string;
  tipoServico?: ACLViewModel;
  get servico(): string {
    return this.tipoServico?.value;
  }


  get restrito() {
    return this.detalhamento?.tpId === 0;
  }
  get sigilo(): string {
    return this.detalhamento?.sigilo;
  }

  get alteracao(): Date {
    return this.detalhamento?.alteracao;
  }

  get perfil(): string {
    return this.detalhamento?.perfil;
  }
  detalhamento?: ServicoLoasEntityModel;
  get modificado(): boolean {
    return this.detalhamento?.modificado;
  }
  set modificado(value: boolean) {
    this.detalhamento.modificado = value;
  }
  get excluido(): boolean {
    return this.detalhamento?.excluido;
  }
  set excluido(value: boolean) {
    this.detalhamento.excluido = value;
  }
  constructor(model: ConstructServicoLoasViewModel) {
    console.log('ConstructServicoLoasViewModel', model);
    if (model.baseModel && model.tipoServico) {
      model.uuid = model.uuid || model.baseModel.uuid;
      model.tipoServico.value = model.tipoServico.value || model.baseModel.tipoServico;
      model.tipoServico.code = model.tipoServico.code || model.baseModel.tpId;
      model.tipoServico.group = model.tipoServico.group || model.tipoServico['grupo'] || model.baseModel.grupo;
      model.sigilo = {
        sigilo: model.sigilo?.sigilo || model.baseModel.sigilo,
        nivel: model.sigilo?.nivel || model.baseModel.sigiloId,
      };
    }

    this.tipoServico = model.tipoServico;
    this.uuid = model.uuid || Guid.newGuid();
    this.detalhamento = {
      tipoServico: model.tipoServico.value,
      tpId: parseInt(model.tipoServico.code),
      alteracao: new Date(),
      perfil: model.baseModel?.perfil || "(Atual)",
      sigilo: model.sigilo?.sigilo || "Restrito a mim",
      sigiloId: model.sigilo?.nivel || 2,
      uuid: this.uuid,
      grupoServico: this.grupoSelecionado,
      campos: Object.keys(model.formValues || model.baseModel?.campos)
        .filter(c => c != 'sigilo' && c != 'tipoId')
        .map(codigo => {
          let value: ValorServicoEntityModel;
          if (model.formValues) {
            value = {
              codigo: codigo,
              valor: model.formValues[codigo]?.resposta || model.formValues[codigo]
            };
          } else if (model.baseModel?.campos) {
            value = model.baseModel?.campos[codigo];
          }
          return value;
        }
        )
    };

    console.log('ServicoLoasViewModel construido', this);
  }

}

export function uriEncode (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      const value = obj[p];
      if(value)
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(value));
    }
  return str.join("&");
}
