<div class="only-pdf">
  <table cellPadding="5px" cellSpacing="5px">
    <tr style="border:0px; width:210mm;">
      <td rowspan="2">
        <img src="{{assetsUrl}}img/favicon.png" style="height:1.2cm;width:1.2cm;float:left;" /></td>
      <td rowspan="2"><img src="{{assetsUrl}}img/siga-texto-logo.png" style="height:1.2cm;width:4.44cm;float:left;" />
      </td>
      <td style="width:100%">
        <h5 style="margin:0px; padding: 0px;">##TITULO</h5>
      </td>
    </tr>
    <tr style="border:0px;">
      <td>##SUBTITULO</td>
    </tr>
  </table>
</div>

<div class="wrapper">
  <div class="sidebar" data-color="white" data-background-color="black" data-image="./assets/img/sidebar-1.jpg">
    <app-sidebar-cmp></app-sidebar-cmp>
    <div class="sidebar-background" style="background-image: url(assets/img/sidebar-1.jpg)"></div>
  </div>
  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>
    <router-outlet></router-outlet>
    <div *ngIf="!isMap()">
      <app-footer-cmp></app-footer-cmp>
    </div>
  </div>
</div>
