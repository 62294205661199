import { environment } from 'src/environments/environment';
import { LocalStorageCacheService } from './../shared/local-storage-cache.service';
import { SettingsService } from './settings.service';
import { Injectable } from '@angular/core';
import { ListaRoteiros } from '../questionario/models/ListaRoteiros';
import { RoteiroQuestoes } from '../questionario/models/RoteiroQuestoes';

@Injectable({
  providedIn: 'root'
})
export class QuestionarioService {

  constructor(private api: SettingsService, private cache: LocalStorageCacheService) {

  }

  listarRoteiros(alvo: string, subtipo: number = null): Promise<ListaRoteiros> {
    if (environment.debug === true) console.log('listarRoteiros alvo:', alvo);
    return this.cache.getItem(`listarRoteiros_${alvo}${(subtipo ? '_' + subtipo : '')}`,
      (arg) => this.api.getByCompleteEndpoint(`/questionario/roteiro?alvo=${alvo}${(subtipo ? `&subtipo=${subtipo}` : '')}`, true)
        .then(e => e as ListaRoteiros));
  }

  listarQuestoesRoteiro(roteiroGuid: string): Promise<RoteiroQuestoes> {
    if (environment.debug === true) console.log('listarQuestoesRoteiro alvo:', roteiroGuid, 'timeout (s):', this.cache.timeout);
    return this.cache.getItem(`listarQuestoesRoteiro-${roteiroGuid}`,
      (arg) => this.api.getByCompleteEndpoint(`/questionario/roteiro/${roteiroGuid}/questoes`, true)
        .then(e => e as RoteiroQuestoes));
  }

  criarAplicacaoIndividuo(roteiro, uid): Promise<any> {
    return this.api.postJsonEndpoint(`/questionario/aplicacoes/individuo/${uid}`, { roteiro }, true);
  }

  criarAplicacaoGrupoFamiliar(roteiro, gid): Promise<any> {
    return this.api.postJsonEndpoint(`/questionario/aplicacoes/grupo-familiar/${gid}`, { roteiro }, true);
  }

  gravarRoteiroQuestionario(aplicacao, roteiro) {
    roteiro.aplicacao = aplicacao.aplicacao;
    return this.api.postJsonEndpoint(`/questionario/roteiro/${aplicacao.roteiro}/aplicacao/${aplicacao.aplicacao}`,
      roteiro, true);
  }
}
