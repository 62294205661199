import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageCacheService {

  constructor() { }
  debug = true;
  timeout = 3600; // 60min
  getTypedItem<T>(key: string, update: Function): Promise<T> {
    return this.getItem(key, update);
  }

  getItem(key: string, update: Function): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (this.debug) { console.log('getItem from cache', key); }
      let json = localStorage.getItem('timeouts');
      let timeouts = json != null ? JSON.parse(json) : {};
      if (!timeouts) {
        timeouts = {};
      }
      if (this.debug) { console.log('Timeout compare', timeouts[key], new Date().getTime(), timeouts[key] && timeouts[key] > new Date().getTime()); }
      if (timeouts[key] && timeouts[key] > new Date().getTime()) {
        const obj = JSON.parse(localStorage.getItem(key));
        if (this.debug) { console.log('Object from Cache', key, obj); }
        resolve(obj);
      } else {
        if (this.debug) { console.log('Cache not found or expired, update it!', update); }
        const called = await (update.call(this))
          .then(result => {
            if (this.debug) { console.log('Update cache returns', result); }
            localStorage.setItem(key, JSON.stringify(result));
            json = localStorage.getItem('timeouts');
            timeouts = json != null ? JSON.parse(json) : {};
            timeouts[key] = new Date().setSeconds(new Date().getSeconds() + this.timeout);
            localStorage.setItem('timeouts', JSON.stringify(timeouts));
            resolve(result);
          })
          .catch(fail => reject(fail));
      }
    });
  }

}
