import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { JwtService } from '../jwt.service';
import { ApiConfiguration } from '../models/ApiConfiguration.model';
import { PageEvent } from '@angular/material/paginator';
import { stringify } from 'querystring';
import { Router } from '@angular/router';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
export interface ApiToken {
  AppKey: string;
  AccessToken: string;
}
export interface UserSession {
  token: ApiToken;
  personalData: {
    CustomerId?: number;
    FullName?: string;
    FirstName?: string;
    Document?: number;
    Address?: string;
    Neighborhood?: string;
    City?: string;
    State?: string;
    Phone?: string;
    Email?: string;
  };
}

declare var $: any;
declare var VMasker: any;
@Injectable()
export class SettingsService {
  appPlatform = 'siga.web';
  apiUrl = '';
  user: any;
  deviceKey: string;
  tokenStored: string;
  config: any = {
    headers: new HttpHeaders(),
    data: null,
    url: '',
    method: 'GET',
    useAccessToken: false
  };
  userSession: UserSession = {
    token: { AppKey: null, AccessToken: null },
    personalData: {}
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notifyToast: ToastrService,
    private router: Router) {
    this.apiUrl = environment.apiBase;
    const _xy = this.lastPosition;
  }

  public getConfig(configEndpoint: ApiConfiguration) {
    const config = configEndpoint;
    this.getDefaultHeaders(config);
    return config;
  }


  showNotification(
    message: string,
    type: 'success' | 'warning' | 'error' | 'info' | 'show' = 'success',
    from: 'top' = 'top', align: 'right' | 'left' | 'center' = 'right',
    timer = 3000,
    title = '',
    progress: boolean = false) {
    $.notify({
      icon: 'add_alert',
      message
    }, {
      type,
      timer,
      className: type,
      placement: {
        from: from,
        align: align
      }
    });
  }

  configureRequest({ method, endpoint, useAccessToken = false, data = null, customHeaders = null }:
    {
      method: 'DELETE' | 'GET' | 'PUT' | 'OPTIONS' | 'POST';
      endpoint: string; useAccessToken?: boolean; data?: any; customHeaders?: HttpHeaders;
    }) {
    return this.getConfig({
      headers: customHeaders,
      data,
      method,
      url: this.getApiUrl() + endpoint,
      useAccessToken
    });
  }

  getDefaultHeaders(config: ApiConfiguration): any {
    if (!config) {
      config = JSON.parse(JSON.stringify(this.config));
    }
    if (!config.headers) {
      config.headers = new HttpHeaders();
    }
    config.headers = config.headers
      .set('Content-Type', 'application/json')
      .set('x-app-version', this.appPlatform);
  }
  private _lastPosition = { lat: 0, lng: 0 };
  get lastPosition() {
    this.getPosition().then(p => { this._lastPosition = p; })
    return this._lastPosition;
  };

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {
        console.log(resp);
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }

  requestApiService(config: ApiConfiguration): Observable<HttpEvent<any>> {
    let request: HttpRequest<any>;
    //if (environment.debug === true) { console.log('requestApiService config', config); }
    if (!config) {
      return new Observable<HttpEvent<any>>();
    }
    config.headers = config.headers
      .set('x-app-position', JSON.stringify(this.lastPosition));
    if (config.method === 'POST' || config.method === 'PUT') {
      request = (new
        HttpRequest(config.method, config.url, config.data, {
          headers: config.headers,
          responseType: 'json'
          , reportProgress: true
        }));
    } else {
      request = (new
        HttpRequest(config.method, config.url,
          {
            headers: config.headers, responseType: 'json', params:
              new HttpParams({ fromString: config.data || '' })
            , reportProgress: true
          }));
    }
    return this.http.request<any>(request);

  }

  getApiUrl() {
    return this.apiUrl;
  }

  getByCompleteEndpoint(endpoint: string, useAccessToken = false, displayAlert = false) {
    return new Promise<any>((accept, reject) => {
      const request = this.configureRequest({
        method: 'GET',
        endpoint,
        useAccessToken
      });
      this.requestApiService(request)
        .subscribe(ok => {
          const event: any = ok;
          if (event.body) {
            accept(event.body);
          }
        }, nok => {
          if (displayAlert === true) {
            if (nok.error && nok.error.code && nok.error.message &&
              Array.isArray(nok.error.errors)) {
              const message: string = (nok.error.errors).join('<br/>') as string;
              Swal.fire({
                titleText: nok.error.message,
                html: message,
                icon: 'warning'
              }).then(result => { });
              return;
            }
          }

          if ((nok && nok.status === 401) || (nok.error && nok.error.status === 401)) {
            this.redirectSessaoExpirada();
            return;
          }
          //if (environment.debug === true) { console.log('service getByCompleteEndpoint requestApiService error', nok); }
          reject(nok.error && typeof (nok.error) === 'string' ? nok.error :
            nok.statusText === 'Unknown Error' ?
              'Não foi possível conectar ao servidor. Verifique a rede e tente novamente.' :
              nok.message);
        });
    });
  }

  setActiveProfile(profile) {
    return this.postJsonEndpoint('/usuarios/atualiza-perfil-ativo', profile, true, false);
  }

  private redirectSessaoExpirada() {
    Swal.fire({
      titleText: 'Sessão Expirada!',
      html: `Sua sessão foi expirada. Entre novamente no sistema, com o seu login/senha.`,
      icon: 'info',
      confirmButtonText: 'OK'
    });
    this.authService?.logout();
    localStorage.removeItem('access_token');
    this.router.navigate(['auth/login']);
  }

  postJsonEndpoint<T>(endpoint: string, data: T, useAccessToken = false, displayAlert = true) {
    return new Promise<T>((accept, reject) => {
      const request = this.configureRequest({
        method: 'POST',
        endpoint,
        useAccessToken,
        data
      });
      this.requestApiService(request)
        .subscribe(ok => {
          const event: any = ok;
          if (event.body) {
            accept(event.body);
          }
        }, nok => {
          //console.log('nok', nok);
          if (displayAlert === true) {
            if (nok.error && nok.error.code && nok.error.message &&
              Array.isArray(nok.error.errors)) {
              const message: string = (nok.error.errors).join('<br/>') as string;
              Swal.fire({
                titleText: nok.error.message,
                html: message,
                icon: 'warning'
              }).then(result => { });
              return;
            }

            if ((nok && nok.status === 401) || (nok.error && nok.error.status === 401)) {
              this.redirectSessaoExpirada();
              return;
            }


          }
          //if (environment.debug === true) { console.log('service getByCompleteEndpoint requestApiService error', nok); }
          reject(nok.error && typeof (nok.error) === 'string' ? nok.error :
            nok.statusText === 'Unknown Error' ?
              'Não foi possível conectar ao servidor. Verifique a rede e tente novamente.' :
              nok.error && nok.error.errors ? ':<br/>' + nok.error.errors.join('<br/>') :
                nok.message ? nok.message : 'Erro ao tentar executar processamento.');
        });
    });
  }

  configurePaginator(result: any, pageEvent: PageEvent) {
    if (pageEvent) {
      if (result.model && result.model.registros) {
        pageEvent.length = result.model.registros;
      } else if (result) {
        pageEvent.length = result.model &&
          result.model.registros ?
          result.model.registros :
          result.resultado ?
            result.resultado.length : 0;
      }
      if (result.model && result.model.pagina) {
        pageEvent.pageIndex = result.model.pagina - 1;
      }
      if (result.model && result.model.tamanho) {
        pageEvent.pageSize = result.model.tamanho;
      }
    }
  }
}

