import { Injectable } from '@angular/core';
import { environment as env, environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SettingsService } from './settings.service';
import * as moment from 'moment';

declare const chrome: any;
declare const $: any;

function sendMessagePromise(message) {
  return new Promise<any>((resolve, reject) => {
    chrome.runtime.sendMessage(environment.idExtensaoIntegradorSiga, message, response => {
      resolve(response);
    });
  });
}


@Injectable({
  providedIn: 'root'
})
export class CadastroUnicoService {
  loading = false;

  constructor(private api: SettingsService) {

  }
  dom = new DOMParser();

  buscarFamilia(uid: string, model: any): Promise<any> {
    const thisService = this;
    return new Promise(async (resolve, reject) => {
      const service = this;
      const buscaFamilia = await sendMessagePromise(
        {
          task: "fetch",
          url: `https://www.cadastrounico.caixa.gov.br/cadun/buscaFamiliaCpfPessoa.do?acao=consultarCpfPessoa`,
          init: {
            body: `acao=iniciarBuscaFamilia&tipoBusca=2&codigoFamiliaComDv=&codigoPessoaNis=${model.nis}&numeroCPF=${model.cpf}&codigoFamilia=&dvFamilia=&nome=&diaNascimento=&mesNascimento=&anoNascimento=&nomeMae=&nomePai=&tipoCertidao=&certidao=&ufCertidao=&numeroRG=&numeroTitulo=&numeroCTPS=`,
            method: "POST"
          },
          get: 'text'
        });
      console.log('FETCH BUSCA FAMILIA - RESULTADO', buscaFamilia);
      if (buscaFamilia.status != 'OK') {
        reject();
        return;
      }
      let html = $('table', buscaFamilia.result.data).parent().html();
      Swal.fire({
        titleText: 'Escolher Família',
        html: html,
        width: '90%'
      });

      globalThis.cadunChamadaFamilia = function (codFamilia, dv, ...args) {
        console.log('BUSCAR FAMILIA', codFamilia, dv, ...args);
        sendMessagePromise(
          {
            task: "fetch",
            url: `https://www.cadastrounico.caixa.gov.br/cadun/executaBuscaFamilia.do`,
            init: {
              body: `acao=consultarCodigoFamiliaLista&codigoFamilia=${codFamilia}&dvFamilia=${dv}`,
              method: "POST"
            },
            get: 'text'
          })
          .then(async dadosFamilia => {
            console.log('dadosFamilia result', dadosFamilia);
            if (dadosFamilia.status != 'OK')
              reject();

            const cad = dadosFamilia.result;
            const dom = new DOMParser();
            //console.log(cad);
            var cadFamiliaDom = dom.parseFromString(cad.data, 'text/html');
            var fields = thisService.parseDomFields(cadFamiliaDom);
            console.log('DADOS CADUNICO', cadFamiliaDom, fields);
            const infoPessoas = fields['informacoesPessoa'];
            const codigosPessoa = fields['codigoMembroFamiliar'].filter(c => c != '');
            codigosPessoa
            fields['membros'] = await Promise.all(
              infoPessoas.map(async (p, i) => ({
                dados: await thisService.visualizarDadosPessoa(fields, p, codigosPessoa[i].value, i),
                documentos: await thisService.visualizarDadosDocumentos(fields, p, codigosPessoa[i].value, i)
              }))
            );
            console.log('INFORMAÇÔES CADUNICO OK', fields);

            resolve(fields);
            Swal.close();
          })
      };// end function globalThis.cadunChamadaFamilia

      //var domExecutaBuscaFamilia = dom.parseFromString(executaBuscaFamilia,'text/html')
    });
  }

  private parseDomFields(dom: Document, selector = 'input,select,textarea'): any {
    var dataFields = [];
    dom.querySelectorAll(selector).forEach((c: any) => dataFields.push(
      {
        id: c.id,
        name: c.name,
        value: c.value,
        checked: c.selected || c.checked || false
      }
    ));
    var fields = {};
    dataFields.forEach(c => {
      var p = { value: c.value, checked: c.checked };
      if (!fields[c.name]) {
        fields[c.name] = p;
      } else {
        if (!Array.isArray(fields[c.name])) {
          fields[c.name] = [fields[c.name]];
        }
        fields[c.name].push(p);
      }
    });
    Object.keys(fields).forEach(key => {
      if (!Array.isArray(fields[key])) {
        fields[key] = fields[key].value;
      }
    });
    return fields;
  }

  async uploadFolhaResumo(uid, pdf: string, files: any = []): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let mimeType: string;
      if (pdf) {
        mimeType = 'application/pdf';
        let bf = Buffer.from(pdf, "base64");
        var f = new File([bf], 'FolhaResumo.pdf', { type: 'application/pdf' })
        files = [f];
      }
      if (files.length === 0) {
        reject('Não foi permitido o acesso ao arquivo selecionado.');
      }
      mimeType = files[0]?.type;
      if (mimeType.match(/application\/pdf/) == null) {
        reject('Permitido somente arquivos PDF.');
        return;
      }


      const request = await this.api.configureRequest({
        method: 'POST',
        endpoint: `/individuo/${uid}/enviar-imagem-documento-preview`,
        useAccessToken: true
      });
      request.headers = request.headers.delete('Content-type');
      const formData: FormData = new FormData();
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < files.length; i++) {
        formData.append('img', files[i], files[i].name);
        break;
      }
      request.data = formData;
      var upload = await this.api.requestApiService(request).toPromise();
      this.loading = false;
      resolve(upload);
    });
  }

  async importarFolhaResumo(uid = null, files: any = [], ignoreMime = false): Promise<any> {
    console.log('importarFolhaResumo', uid, files, ignoreMime);
    return new Promise(async (resolve, reject) => {
      let mimeType: string;

      if (files.length === 0) {
        reject('Não foi permitido o acesso ao arquivo selecionado.');
      }
      mimeType = files[0]?.type;
      if (!ignoreMime && mimeType.match(/application\/pdf/) == null) {
        reject('Permitido somente arquivos PDF.');
        return;
      }
      if (ignoreMime) {
        mimeType = 'application/pdf';
        let bf = Buffer.from(files, "base64");
        var f = new File([bf], 'FolhaResumo.pdf', { type: 'application/pdf' })
        files = [f];
      }
      const request = await this.api.configureRequest({
        method: 'POST',
        endpoint: `/cadastro-unico/importar-folha-resumo`,
        useAccessToken: true
      });
      request.headers = request.headers.delete('Content-type');
      const formData: FormData = new FormData();
      if (uid) {
        formData.append('uid', uid);
      }
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < files.length; i++) {
        formData.append('pdf', files[i], files[i].name);
        break;
      }
      request.data = formData;
      var upload = await this.api.requestApiService(request).toPromise();
      this.loading = false;
      resolve(upload);
    });
  }

  async baixarFolhaResumo(postExecution: Function = null) {
    return new Promise(async (resolve, reject) => {

      const pdfBase64 = await sendMessagePromise(
        {
          task: "fetch",
          url: "https://www.cadastrounico.caixa.gov.br/cadun/ImprimirFolhaResumoServlet",
          init: {
            method: "GET",
            redirect: 'follow'
          },
          get: 'body'
        });
      console.log('FolhaResumo PDF - result', pdfBase64);
      if (pdfBase64.status == 'OK') {
        let result: any;
        if (postExecution) {
          result = await postExecution(pdfBase64.result);
        } else {
          result = pdfBase64.result.data;
        }

        resolve(result);
      }
    });

  };


  visualizarDadosPessoa(model, infoPessoa, codPessoa, ordem) {
    const thisService = this;
    return sendMessagePromise(
      {
        task: "fetch",
        url: "https://www.cadastrounico.caixa.gov.br/cadun/carregarTelasPessoa.do?acao=visualizar",
        init: {
          body: `acao=visualizar&codigoTelaAtual=1&codigoFamilia=${model.codFamilia}&dvFamilia=${model.dvFamilia}&codigoMembroFamiliar=${codPessoa}`
            + `&tipoFormulario=0&continuarAtualizacaoPessoa=true&urlBuscaNis=iniciarMinhaCasaMinhaVida.do%3Facao%3DiniciarMinhaCasaMinhaVida`
            + `&informacoesFamilia=${infoPessoa}&numeroOrdem=${ordem}`,
          method: "POST"
        },
        get: 'text'
      }).then(c => {
        if (c.status == 'OK') {
          const dom = new DOMParser();
          //console.log(cad);
          let cadMembroDom = dom.parseFromString(c.result.data, 'text/html');
          console.log(c, cadMembroDom);
          var docFields = ['nome', 'nis', 'apelido', 'sexo', 'diaNascimento', 'mesNascimento', 'anoNascimento', 'parentesco', 'corRaca', 'nomeMae', 'nomeMaeCheck', 'nomePai', 'nomePaiCheck', 'localNascimento', 'estadoNascimento', 'estadoNascimentoCheck', 'municipioNascimento', 'municipioNascimentoCheck', 'paisNascimento', 'paisNascimentoCheck', 'opcaoRegistroCartorio'];
          const selector = docFields.map(c => `[name=${c}]`).join(',');
          console.log(selector, docFields);
          var fields = thisService.parseDomFields(cadMembroDom, selector);
          return fields;

        }
        return null;
      });
  }

  visualizarDadosDocumentos(model, infoPessoa, codPessoa, ordem) {
    const thisService = this;
    return sendMessagePromise(
      {
        task: "fetch",
        url: "https://www.cadastrounico.caixa.gov.br/cadun/visualizarFormularioDocumentos.do?acao=visualizar",
        init: {
          body: `acao=consultarCodigoFamiliaLista&codigoFamilia=${model.codFamilia}&dvFamilia=${model.dvFamilia}&codigoMembroFamiliar=${codPessoa}`
            + `&tipoFormulario=0&continuarAtualizacaoPessoa=true&urlBuscaNis=iniciarMinhaCasaMinhaVida.do%3Facao%3DiniciarMinhaCasaMinhaVida`
            + `&informacoesFamilia=${infoPessoa}&numeroOrdem=${ordem}`,
          method: "POST"
        },
        get: 'text'
      }).then(c => {
        if (c.status == 'OK') {
          const dom = new DOMParser();
          //console.log(cad);
          let cadMembroDom = dom.parseFromString(c.result.data, 'text/html');
          //  console.log(c, cadMembroDom);
          var docFields = ['tipoDadosCertidao', 'nomeCartorio', 'diaRegistroCertidao', 'mesRegistroCertidao', 'anoRegistroCertidao', 'numeroLivro', 'numeroFolha', 'numeroTermoRani', 'numeroMatricula', 'estadoRegistro', 'municipioRegistro', 'municipioRegistroDescricao', 'cpf', 'numeroIdentidade', 'complementoIdentidade', 'diaRegistroIdentidade', 'mesRegistroIdentidade', 'anoRegistroIdentidade', 'estadoEmissorIdentidade', 'siglaOrgaoEmissor', 'numeroCarteiraTrabalho', 'serieCarteiraTrabalho', 'diaRegistroCarteiraTrabalho', 'mesRegistroCarteiraTrabalho', 'anoRegistroCarteiraTrabalho', 'estadoEmissorCarteiraTrabalho', 'numeroTituloEleitoral', 'zonaTituloEleitoral', 'secaoTituloEleitoral'];
          const selector = docFields.map(c => `[name=${c}]`).join(',');
          //console.log(selector, docFields);
          var fields = thisService.parseDomFields(cadMembroDom, selector);
          return fields;

        }
        return null;
      });
  }

  converteGrauParentesco(model, parentesco) {
    /*
      <option value="1" selected="selected">1 - PESSOA RESPONSAVEL PELA UNIDADE FAMILIAR</option><option value="2">2 - CONJUGE OU COMPANHEIRO(A)</option>
      <option value="3">3 - FILHO(A)</option>
      <option value="4">4 - ENTEADO(A)</option>
      <option value="5">5 - NETO(A) OU BISNETO(A)</option>
      <option value="6">6 - PAI OU MAE</option>
      <option value="7">7 - SOGRO(A)</option>
      <option value="8">8 - IRMAO OU IRMA</option>
      <option value="9">9 - GENRO OU NORA</option>
      <option value="10">10 - OUTRO PARENTE</option>
      <option value="11">11 - NAO PARENTE</option></select>
    */


  }

}

