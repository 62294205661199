<nav #navbar class="navbar navbar-expand-lg navbar-transparent  navbar-relative">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button mat-raised-button (click)="minimizeSidebar()" class="btn btn-just-icon btn-white btn-fab btn-round">
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button>
      </div>
      <a class="navbar-brand" href="{{getPath()}}"> {{getTitle()}}</a>
    </div>
    <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Mostrar/Esconder</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)">
            <i class="material-icons">dashboard</i>
            <p>
              <span class="d-lg-none d-md-block">Painel</span>
            </p>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">notifications</i>
            <span class="notification">{{alertService.unreadCount | async}}</span>
            <p>
              <span class="d-lg-none d-md-block">Alertas</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink"
            *ngIf="(alertService.alerts.asObservable() | async).length >0">
            <a class="dropdown-item" *ngFor="let alerta of (alertService.alerts.asObservable() | async)">
              <button mat-buton mat-flat-button (click)="alertService.mostrarMensagem(alerta)">
                <mat-icon *ngIf="alerta.read ===true" matTooltip="Lido em {{alerta.readAt | date:'dd/MM/yyyy HH:mm'}}">
                  mark_email_read</mat-icon>
                <mat-icon *ngIf="alerta.read ===false" matTooltip="Mensagem não Lida">email</mat-icon>

                <strong class="ml-auto">{{alerta.subject}}</strong>
              </button>
            </a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" mat-button (click)="auth.logout()">
            <i class="material-icons">exit_to_app</i>
            <p>
              <span class="d-lg-none d-md-block">Sair</span>
            </p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
