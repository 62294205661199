<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img src="/assets/img/favicon.png" />
    </div>
  </a>

  <a href="#" class="simple-text logo-normal">
    <img src="/assets/img/siga-texto-logo.png" />
  </a>
</div>


<div class="sidebar-wrapper">
  <div id="formWrapper" style="display: none;"></div>
  <div class="user">
    <div class="photo">
      <img src="./assets/img/default-avatar.png" />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" href="#collapseExample" class="collapsed">
        <span>
          {{user.name }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="collapse" id="collapseExample">
        <div class="perfil mr-4">
          <span><small>Perfil: {{ user.perfilAtivo.Cargo }}/{{ user.perfilAtivo.Equipamento }}</small></span>
        </div>
        <ul class="nav">
          <li class="nav-item">
            <a href="/#/usuario/trocar-senha" class="nav-link">
              <span class="sidebar-mini">S</span>
              <span class="sidebar-normal">Trocar Senha</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="/#/usuario/perfil" class="nav-link">
              <span class="sidebar-mini">MP</span>
              <span class="sidebar-normal">Perfil</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="/#/usuario/disponibilidade" class="nav-link">
              <span class="sidebar-mini">CD</span>
              <span class="sidebar-normal">Configurar Disponibilidade</span>
            </a>
          </li>

          <!-- <li class="nav-item">
            <a href="/#/usuario/configuracoes" class="nav-link">
              <span class="sidebar-mini">S</span>
              <span class="sidebar-normal">Configurações</span>
            </a>
          </li> -->
        </ul>
      </div>

    </div>
  </div>
  <div *ngIf="isMobileMenu()">
    <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form>
    <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)">
          <i class="material-icons">dashboard</i>
          <p>
            <span class="d-lg-none d-md-block">Painel</span>
          </p>
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="material-icons">notifications</i>
          <span class="notification">{{alertService.unreadCount | async}}</span>
          <p>
            <span class="d-lg-none d-md-block">Alertas</span>
          </p>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item"
            *ngFor="let alert of (alertService.alerts.asObservable() | async)">{{alert.message}}</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)">
          <i class="material-icons">person</i>
          <p>
            <span class="d-lg-none d-md-block">Perfil do usuário</span>
          </p>
        </a>
      </li>
    </ul>
  </div>


  <ul class="nav" *ngIf="(atendimentoCorrente.asObservable() | async)?.requerente !==null">
    <li routerLinkActive="active" class="nav-item">
      <a href="/#/atendimento/novo-atendimento?uid={{queryParamsAtendimento.uid}}&chave={{queryParamsAtendimento.chave}}"
        class="nav-link">
        <i class="material-icons">assignment</i>
        <div class="info-ficha">
          <p>Nº: {{atendimentoCorrente.value.codigo}}</p>
          <p>{{atendimentoCorrente.value.requerente.nomeCompleto}}</p>
        </div>
      </a>
    </li>
  </ul>

  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <!--If is a single link-->
      <a *ngIf="!menuitem.callback && menuitem.path.indexOf('https://')==-1 && menuitem.type === 'link'"
        [routerLink]="[menuitem.path]" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}</p>
      </a>
      <a *ngIf="!menuitem.callback && menuitem.path.indexOf('https://')>-1" [href]="menuitem.path" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}</p>
      </a>
      <a *ngIf="menuitem.callback" (click)="this.callback(menuitem.callback)" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()"
        class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p><strong>{{menuitem.title}}</strong><b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>

</div>
