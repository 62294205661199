import { environment } from 'src/environments/environment';
import { ICadastroGrupoFamiliarModel, ICadastroMembroGrupoModel } from './../models/CadastroGrupoFamiliar.model';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { IResumoCadastroGrupoFamiliarModel, CadastroGrupoFamiliarModel } from '../models/CadastroGrupoFamiliar.model';
import { PageEvent } from '@angular/material/paginator';
import { LocalStorageCacheService } from '../shared/local-storage-cache.service';
import { Guid } from '../shared/Guid';
import { CadastroIndividuoModel } from '../models/CadastroIndividuoModel';

const sessionName = 'cadastroGrupoFamiliarModel';
@Injectable({
  providedIn: 'root'
})
export class GrupoFamiliarService {

  excluirTodasAtualizacoes() {
    sessionStorage.removeItem(sessionName + 'Updated');
  }

  eliminarPersistencia() {
    sessionStorage.removeItem(sessionName);
    sessionStorage.removeItem(sessionName + 'Updated');
  }
  carregarContadoresAtendimentoEquipamento(gid: any): any {

    return this.api.postJsonEndpoint<{ gid?: string, descricao?: string, count?: Number }>
      ('/grupo-familiar/atendimentos-por-equipamento',
        { gid }, true);

  }
  private currentModelInstance: CadastroGrupoFamiliarModel;

  constructor(private api: SettingsService, private cache: LocalStorageCacheService) {
    cache.timeout = 60;
  }

  get currentModel(): CadastroGrupoFamiliarModel {
    this.currentModelInstance = JSON.parse(sessionStorage.getItem(sessionName));
    if (!this.currentModelInstance) {
      this.currentModelInstance = new CadastroGrupoFamiliarModel();
      this.currentModelInstance.guid = Guid.newGuid();
      this.currentModelInstance.dadosGrupo = {};
      this.currentModelInstance.domicilio = {};
      this.currentModelInstance.membros = {};

      const resp: CadastroIndividuoModel = {};
      resp.uid = Guid.newGuid();
      resp.parentescoId = 1;
      this.currentModelInstance.responsavelUuid = resp.uid;
      this.currentModelInstance.membros[resp.uid] = resp;
    }
    this.currentModel = this.currentModelInstance;

    return this.currentModelInstance;
  }
  set currentModel(value: CadastroGrupoFamiliarModel) {
    this.currentModelInstance = value;
    if (!this.currentModelInstance) {
      sessionStorage.removeItem(sessionName);
      return;
    }
    sessionStorage.setItem(sessionName, JSON.stringify(this.currentModelInstance));
  }
  public isModelEmpty() {
    this.currentModelInstance = JSON.parse(sessionStorage.getItem(sessionName));
    return this.currentModelInstance === null || this.currentModelInstance === undefined;
  }


  setUpdated(property, source) {
    let updated = JSON.parse(sessionStorage.getItem(sessionName + 'Updated'));
    if (!updated) {
      updated = {};
    }
    if (!source) {
      delete updated[property];
    } else {
      updated[property] = source;
    }

    sessionStorage.setItem(sessionName + 'Updated', JSON.stringify(updated));

  }

  isUpdated(property): boolean {
    const updated = JSON.parse(sessionStorage.getItem(sessionName + 'Updated'));
    return updated !== null && updated !== undefined
      && updated[property] !== null && updated[property] !== undefined;
  }


  resultadoPesquisa: any;
  resultadoPesquisaSolicitante: any;
  resultadoPesquisaGrupoFamiliar: any;


  /**
   * Resumo de dados do grupo Familiar
   * @param groupGuid guid do grupo familiar
   */
  grupoFamiliar(groupGuid: string): Promise<IResumoCadastroGrupoFamiliarModel> {
    if (!groupGuid) {
      return Promise.resolve<IResumoCadastroGrupoFamiliarModel>(null);
    }
    //console.log('grupoFamiliar guid:', groupGuid);
    return this.api.postJsonEndpoint<IResumoCadastroGrupoFamiliarModel>('/grupo-familiar/resumo',
      { gid: groupGuid }, true);
  }

  excluirMembroGrupo(gid, uid): Promise<any> {
    return this.api.postJsonEndpoint<any>(`/grupo-familiar/excluir-membro/${gid}`,
      { uid }, true);
  }

  carregarQuestionarios(uuid: any): Promise<any> {
    return this.carregarPropriedadeGrupoFamiliar('questionarios', uuid, true);
  }

  public carregarPropriedadeGrupoFamiliar(property, guid, isArray = false) {
    return this.api.getByCompleteEndpoint(`/grupo-familiar/carregar-${property}/${guid}`, true)
      .then((result) => {
        if (environment.debug === true) {
          //console.log('carregarPropriedadeGrupoFamiliar OK', property, guid, result);
        }
        return result;
      }).catch(error => {
        if (environment.debug === true) {
          //console.log('carregarPropriedadeGrupoFamiliar ERROR', property, guid, error);
        }
        return isArray ? [] : {};
      });
  }


  /**
   * Pesquisa grupo familiar
   * @param params parametros de pesquisa - consulta, e paginação (pagina, tamanho)
   */
  pesquisarGrupoFamiliar(params, pageEvent: PageEvent): Promise<any> {
    this.resultadoPesquisaGrupoFamiliar = null;
    params.tamanho = parseInt(pageEvent.pageSize.toString(), 10);
    params.pagina = parseInt((pageEvent.pageIndex + 1).toString(), 10);
    return this.api.postJsonEndpoint('/grupo-familiar/pesquisar-grupo',
      params, true)
      .then((result) => {
        this.resultadoPesquisaGrupoFamiliar = result;
        this.api.configurePaginator(result, pageEvent);
        return result;
      });
  }
  /**
   * Pesquisa grupo familiar do solicitante do atendimento
   * @param params parametros de pesquisa - consulta, e paginação (pagina, tamanho)
   */
  pesquisarGrupoFamiliarSolicitante(params, pageEvent: PageEvent): Promise<any> {
    this.resultadoPesquisaSolicitante = null;
    params.tamanho = parseInt(pageEvent.pageSize.toString(), 10);
    params.pagina = parseInt((pageEvent.pageIndex + 1).toString(), 10);
    return this.api.postJsonEndpoint('/grupo-familiar/pesquisar-grupo',
      params, true)
      .then((result) => {
        this.resultadoPesquisaSolicitante = result;
        this.api.configurePaginator(result, pageEvent);
      });
  }

  /**
   * Filtrar grupos familiares no mapa
   * @param params parametros de pesquisa
   */
  pesquisarGrupoFamiliarMapa(params): Promise<any> {
    //console.log('pesquisarGrupoFamiliarMapa');
    // return this.cache.getItem('todoMap', (arg) => new Promise<any>((resolve, reject) => resolve([{
    //   latitude: -23.5463,
    //   longitude: -47.44049, title: 'Prefeitura Municipal de Votorantim',
    //   address: 'Votorantim'
    // }]))).then(r => {
    //   //if (environment.debug === true) { console.log('todoMap cache', r); }
    //   return r;
    // });

    return this.cache.getItem(`pesquisarGrupoFamiliarMapa-${JSON.stringify(params)}`,
      (arg) => this.api.getByCompleteEndpoint(`/grupo-familiar/pesquisar-mapa?q=${params}`,
        true)
        .then(r => {
          if (environment.debug === true) { console.log('pesquisarGrupoFamiliarMapa cache', r); }
          return r;
        }));

  }

  public updatePersistedModel(property, source) {
    //console.log(`${property} persist Update`, source);
    const m = this.currentModel;
    if (Array.isArray(source)) {
      m[property] = source.filter(item => !item.excluido);
      m[property + 'Deleted'] = source.filter(item => item.excluido);
    } else {
      m[property] = source;
    }
    this.currentModel = m;
    //console.log(`${property} model Updated`, this.currentModel);

  }

  public inserirNovoGrupo(grupo: CadastroGrupoFamiliarModel): Promise<any> {
    //console.log('grupoFamiliar guid:', groupGuid);
    return this.api.postJsonEndpoint<any>('/grupo-familiar/novo-grupo-familiar',
      grupo, true);
  }

  listarSelecaoMembros(gid: any) {
    return this.api.getByCompleteEndpoint(`/grupo-familiar/listar-membros/${gid}`, true);
  }

}
