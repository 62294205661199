import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Component, ElementRef, EventEmitter, Output, ViewChild, Input, forwardRef, Renderer2 } from '@angular/core';

export const VAL_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SearchInputComponent),
  multi: true,
};


@Component({
  selector: 'app-search-input',
  styleUrls: ['./search-input.component.scss'],
  templateUrl: './search-input.component.html',
  providers: [VAL_ACCESSOR]
})
export class SearchInputComponent implements ControlValueAccessor {
  @ViewChild('input', { static: true }) input: ElementRef;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder = 'Pesquisar';
  @Input() value = '';
  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  propagateChange = (_: any) => { };
  private propagateTouched = (_: any) => { };

  @Input() searchOnChange = true;
  constructor(private _renderer: Renderer2) {

    //console.log('SEARCH INPUT', this);
  }
  writeValue(value: any): void {
    this._renderer.setProperty(this.input.nativeElement, 'value', value);
  }
  registerOnChange(fn: any): void {
    //console.log('registerOnChange', fn);
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    //console.log('registerOnTouched', fn);
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this.input.nativeElement, 'disabled', isDisabled);
  }


  isInputShown = false;

  showInput() {
    this.isInputShown = true;
    this.input.nativeElement.focus();
  }

  hideInput() {
    this.isInputShown = false;
  }

  doSearch() {

    this.onInput(this.input.nativeElement.value);
  }

  onInput(val: string) {
    this.propagateChange(val);
    this.search.emit(val);
  }
}
