<ng-container *ngIf="form &&  model && (arrayRespostas| async)">
  <div [formGroup]="form">
    <mat-form-field [floatLabel]="true" style="width:100%">
      <mat-label>{{placeHolder}}</mat-label>
      <mat-select [formControl]="control" [compareWith]="compareId" multiple [placeholder]="placeHolder">
        <mat-option *ngFor="let value of  (arrayRespostas| async); let i =index" [value]="value.chave">
          {{value.descricao}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
