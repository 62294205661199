import { DominiosService } from 'src/app/services/dominios.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  _router: any;
  updateApp(): void {
    document.location.reload();
    console.log("The app is updating right now");

  }


  constructor(public updates: SwUpdate, private router: Router, private activatedRoute: ActivatedRoute, private dominios: DominiosService) {

    const updatesAvailable = updates.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })));

    updatesAvailable.filter(event => event.type == 'UPDATE_AVAILABLE')
      .forEach(event => {
        updates.activateUpdate().then(ok => {
          console.log('current version was', event.current);
          console.log('new version is', event.available);
        });
      });


  }

  ngOnInit() {
    this.dominios.getTiposRenda();
    this.dominios.getTiposAtendimento();
    this.dominios.getTiposCertidao();
    this.dominios.getTiposContatos();


    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {

      const body = document.getElementsByTagName('body')[0];
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (body.classList.contains('modal-open')) {
        body.classList.remove('modal-open');
        modalBackdrop.remove();
      }
    });
  }
}
