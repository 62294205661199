import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { JwtService } from '../jwt.service';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
import { SettingsService } from './settings.service';
import { ToastrService } from 'ngx-toastr';



export interface JwtTokenResult {
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'?: string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'?: string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'?: string;
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'?: string[];
  exp?: number;
  iss?: string;
  aud?: string;
}

const roleClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _lastPosition = { lat: 0, lng: 0 };
  get lastPosition() {
    this.getPosition().then(p => { this._lastPosition = p; })
    return this._lastPosition;
  };

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }

  getAccessToken(): string {
    return this.jwt.getToken();
  }
  getUserTokenDecoded(): any {
    const user = this.jwt.decodedToken;
    user.name = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
    user.email = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
    if (!user['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata']) {
      Swal.fire({
        titleText: 'Inconsistência no Acesso',
        text: 'Não foi carregado nenhum perfil de usuário no seu login, '
          + 'caso tenha corrigido o seu usuário, favor tentar acessar o sistema novamente.',
        icon: 'warning'
      })
        .then(() => this.logout());
      return null;
    }
    user.perfis = [];
    let userData = user['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
    //console.log('UserData', userData);

    if (Array.isArray(userData)) {
      userData.forEach(ud => user.perfis.push(JSON.parse(ud))); JSON.stringify(userData);
    } else {
      user.perfis.push(JSON.parse(userData));
    }

    user.perfilAtivo = user.perfis.filter(c => c.Padrao === true)[0];
    return user;
  }
  setTemporaryPerfilModel(propriedade: string, valor: any) {
    localStorage.setItem('perfil_' + propriedade, valor);
  }

  getTemporaryPerfilModel(propriedade: string) {
    return localStorage.getItem('perfil_' + propriedade);
  }

  async isInRole(role: string | string[]): Promise<boolean> {
    let isInRole = false;
    try {
      if (!role || role.length == 0)
        return true;

      if (Array.isArray(role)) {
        var all = await Promise.all(role.map(anyRole => this.isInRole(anyRole)));
        const isInAnyRole = all.filter(c => c === true).length > 0;
        environment.debug && console.log('isInRole (multiple) result', role, isInAnyRole);
        return isInAnyRole;
      }
      const roles = this.jwt.decodedToken[roleClaim];
      if (roles) {
        const hasRole = roles.filter(c => c === role);
        isInRole = hasRole && hasRole.length > 0;
      }
    } catch {
      isInRole = false;
    }
    environment.debug && console.log('isInRole result', role, isInRole);
    return isInRole;
  }


  message: any;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  public currentUser: any = {};
  constructor(
    public httpClient: HttpClient,
    public router: Router,
    public jwt: JwtService,
    public notifyToast: ToastrService) {
    var xy = this.lastPosition;

  }

  getOptionsWithHeader() {
    var headers = this.headers
      .set('x-app-position', JSON.stringify(this.lastPosition));
    return { headers: headers };
  }

  login(email: string, password: string, recaptcha: string) {

    var headers = this.headers
      .set('x-app-position', JSON.stringify(this.lastPosition));
    return this.httpClient.post<{ access_token: string, name: string, email: string }>(environment.apiBase + '/login',
      { email, password, recaptcha }, this.getOptionsWithHeader())
      .pipe(
        tap(res => {
          //console.log(this.jwt.decodedToken);
          localStorage.setItem('access_token', res.access_token);
          this.currentUser.token = res.access_token;
          this.currentUser.expiration = this.jwt.expirationDate;
          this.currentUser.sid = this.jwt.decodedToken.sid;
          this.currentUser.name = res.name;
          this.currentUser.email = res.email;
          this.currentUser.profile = null;
          this.currentUser.claims = [];
          forkJoin([
            this.getUserProfile(),
            //this.getUserEquipments()
          ])
            .subscribe((res) => {
            }, console.error, () => this.router.navigate(['dashboard']));
        })
      );
  }

  nokAlert = (error) => {
    //console.log('NOK', error);
    if (error && error.statusText && error.statusText.indexOf('Unknown Error') > -1) {
      error = 'Serviço em Manutenção, favor aguardar alguns instantes para tentar novamente.';
      this.showNotification(
        (error.message || error.statusText || error), 'error');
    } else if (error.error && error.error.code && error.error.message &&
      Array.isArray(error.error.errors)) {
      const message: string = (error.error.errors).join('<br/>') as string;
      Swal.fire({
        titleText: 'Falha na Consulta',
        html: message,
        icon: 'warning'
      }).then(result => { });
      // return;
    }
  }


  public logout() {
    this.jwt.logout();
    this.router.navigate(['auth/login']);
  }

  esqueciSenha(email: string, recaptcha: string) {
    return this.httpClient.post<{ message: string }>(environment.apiBase + '/login/esqueci-senha',
      { email, recaptcha }, this.getOptionsWithHeader())
      .pipe(
        tap(res => {
          Swal.fire({
            titleText: 'Recuperação de Senha',
            text: 'Um link para a recuperação da senha foi enviado para o seu e-mail cadastrado.' +
              'Você deve acessar esse link e alterar a sua senha em até 15 minutos.',
            icon: 'info'
          }).finally(() => this.router.navigate(['auth/login']));
        }, this.nokAlert)
      );
  }

  recuperarSenha(form: any, recaptcha: string) {
    const _this = this;
    return this.httpClient.post<{ message: string }>(environment.apiBase + '/login/trocar-senha',
      {
        senhaAntiga: form.oldPassword, senhaNova: form.password, recaptcha
      }, this.getOptionsWithHeader())
      .pipe(
        tap((res) => {
          Swal.fire({
            titleText: 'Recuperação de Senha',
            text: 'Recuperação de senha realizada com sucesso, entre com o seu login do sistema e a senha nova.',
            icon: 'success'
          }).finally(() => _this.logout());
        },
          this.nokAlert,
          () => localStorage.removeItem('access_token'))
      );
  }
  trocarSenha(form: any, recaptcha: string) {
    return this.httpClient.post<{ message: string }>(environment.apiBase + '/login/trocar-senha',
      {
        senhaAntiga: form.oldPassword, senhaNova: form.password, recaptcha
      }, this.getOptionsWithHeader())
      .pipe(
        tap(res => {
          Swal.fire({
            title: 'Troca de Senha',
            text: 'Senha modificada como sucesso!',
            icon: 'success'
          });
          this.router.navigate(['dashboard']);
        }, this.nokAlert)
      );
  }



  showNotification(message: string, type: 'success' | 'warning' | 'error' | 'info' | 'show' = 'success', from = 'top', align = 'right') {

    $.notify({
      icon: 'add_alert',
      message
    }, {
      type,
      timer: 4000,
      placement: {
        from,
        align
      }
    });
  }

  getUserProfile() {
    return this.httpClient.get<any[]>
      (environment.apiBase + '/usuarios/user-profiles', this.getOptionsWithHeader()).pipe(
        tap(res => {
          this.currentUser.profiles = res;
          const padraoFilter = res.filter(c => c.padrao === true);
          this.currentUser.profile = padraoFilter && padraoFilter.length > 0 ? padraoFilter[0] : res[0];
        })
      );
  }


  getUserEquipments() {
    return this.httpClient.get<{ uuid: string, email: string, nome: string }>
      (environment.apiBase + '/usuarios/equipamentos', this.getOptionsWithHeader()).pipe(
        tap(res => {
          this.currentUser.equipments = res;
        })
      );
  }
}


