import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PesquisarAtendimentoComponent } from './../atendimento/pesquisar-atendimento/pesquisar-atendimento.component';


import { LoaderModule } from './../shared/loader/loader.module';
import { SearchInputModule } from './../shared/search-input/search-input.module';
import { MaterialModule } from "./../material.module";

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { QRCodeModule } from 'angularx-qrcode';
import { SafePipe, ArquivosComponent } from './arquivo/arquivos/arquivos.component';
import { BtnNovoArquivoComponent } from './arquivo/btn-novo-arquivo/btn-novo-arquivo.component';
import { BtnListarArquivosComponent } from './arquivo/btn-listar-arquivos/btn-listar-arquivos.component';
import { ToolbarArquivosComponent } from './arquivo/toolbar-arquivos/toolbar-arquivos.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SelecaoMembroFamiliarComponent } from './campos/selecao-membro-familiar/selecao-membro-familiar.component';


const componentes = [PesquisarAtendimentoComponent, SafePipe,  ArquivosComponent, BtnNovoArquivoComponent, BtnListarArquivosComponent, ToolbarArquivosComponent, SelecaoMembroFamiliarComponent];

@NgModule({
  declarations: componentes,
  imports: [
    CommonModule,
    SweetAlert2Module,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MaterialModule,
    LoaderModule,
    SearchInputModule,
    ReactiveFormsModule,
    QRCodeModule,
    NgxDropzoneModule,
  ],
  exports: componentes
})
export class SharedComponentsModule { }
