 <div *ngIf="form" [formGroup]="form" class="mt-4 mb-4">
   <div class="row">
     <div class="col-md-2">
       <mat-form-field [floatLabel]="true" *ngIf="viewModel && viewModel.tipos">
         <mat-label>Tipo de {{grupo}}</mat-label>
         <mat-select #tipo formControlName="tipo" [ngClass]="displayFieldCss('tipo')"
           (selectionChange)="selecionarTipo($event)" required>
           <mat-option *ngFor="let op of viewModel.tipos" value="{{op}}">{{op}}</mat-option>
         </mat-select>
       </mat-form-field>
     </div>
     <div class="col-md-4">
       <mat-form-field [floatLabel]="true">
         <mat-label>Visão</mat-label>
         <mat-select formControlName="visao">
           <mat-option *ngFor="let op of viewModel.visoes" value="{{op}}">{{op}}</mat-option>
         </mat-select>
       </mat-form-field>
     </div>
     <div class="col-md-4">
       <mat-form-field [floatLabel]="true">
         <mat-label>Observações</mat-label>
         <input matInput formControlName="observacoes" placeholder="Observações sobre o arquivo de {{grupo}}">
       </mat-form-field>
     </div>
     <div class="col-md-2">
       <button mat-button class="btn btn-link" type="submit" (click)="salvarArquivo()">
         {{editing ? 'Atualizar':'Inserir'}}
         <mat-icon class="md-xs-16 ml-xs-0 mr-xs-auto" aria-hidden="false" title="Editar o {{grupo}}"
           aria-label="Editar">
           save_alt</mat-icon>
       </button>
     </div>
   </div>
   <div class="row">
     <div class="col-md-12" formGroupName="arquivo">
       <input #fileUuid type="hidden" formControlName="uuid" />
       <input #fileUrl type="hidden" formControlName="url" />
       <input #fileBucket type="hidden" formControlName="bucket" />
       <input type="file" [accept]="acceptMimeTypes" capture style="display:none" #camera (change)="previewCamera()" />

       <ngx-dropzone #dropFile [multiple]="false" [accept]="acceptMimeTypes" [maxFileSize]="25*1024*1024"
         class="dropzone-arquivos" (change)="preview($event?.addedFiles)"
         [disableClick]="(currentFile && currentFile.url)">
         <ngx-dropzone-label *ngIf="!(currentFile && currentFile.url && getSafeGoogleDocsUrl(currentFile?.download))">
           <div class="btn btn-success col-md-5 mr-auto">
             <mat-icon>cloud_upload</mat-icon>
             Selecionar Arquivo
           </div>
           <div class="btn btn-info col-md-5 ml-auto" (click)="tirarFoto($event)">
             <mat-icon>camera</mat-icon> Tirar Foto
           </div>
           ou arraste aqui... (jpg,png,bmp,gif,tiff,doc,docx,xls,xlsx ou pdf - máx. 25MB)
         </ngx-dropzone-label>
         <ng-container *ngIf="currentFile?.download">
           <iframe [src]="viewerUrl | safe" style="width:100%; height:500px;" frameborder="0"></iframe>

         </ng-container>
         <img #imgPreview *ngIf="currentFile && currentFile.url && currentFile?.mime?.indexOf('image')!==-1"
           class="image img-raised h-100 mx-auto" alt="..." style="max-width: 70vw;"
           [src]="currentFile && currentFile.url ? currentFile.url : './assets/img/default-avatar.png'">
       </ngx-dropzone>
     </div>
   </div>
   <div class="row">
     <div class="col-md-12">
       <table #table mat-table [dataSource]="tableSource" class="cert-table mat-elevation-z1">
         <ng-container matColumnDef="tipo">
           <th mat-header-cell align="left" *matHeaderCellDef> Tipo </th>
           <td mat-cell align="left" *matCellDef="let element">
             <a href="javascript:void(0);" (click)="editarArquivo(element)">{{element.tipo}}</a></td>
         </ng-container>
         <ng-container matColumnDef="arquivo">
           <th mat-header-cell align="left" *matHeaderCellDef>Imagem</th>
           <td mat-cell align="left" *matCellDef="let element">
             <a *ngIf="element.arquivo?.download" [href]="element.arquivo.download">
               <mat-icon>download</mat-icon> Baixar Arquivo
             </a>

             <img *ngIf="element.arquivo.url && element.arquivo.url && element.arquivo?.mime?.indexOf('image')!==-1"
               [src]="element.arquivo.url" class="image img-responsive img-thumbnail hidden-xs"
               style="max-height: 60px;" [matTooltip]="element.observacoes" />
           </td>
         </ng-container>
         <ng-container matColumnDef="visao">
           <th mat-header-cell align="left" *matHeaderCellDef> Visão </th>
           <td mat-cell align="left" *matCellDef="let element"> {{element.visao}} </td>
         </ng-container>
         <ng-container matColumnDef="tempId">
           <th class="d-none d-md-table-cell" align="left" mat-header-cell *matHeaderCellDef> Ações </th>
           <td class="d-none d-md-table-cell" align="left" mat-cell *matCellDef="let element">
             <mat-icon class="md-xs-16 ml-xs-0 mr-xs-auto" aria-hidden="false" title="Editar o {{grupo}}"
               aria-label="Editar" (click)="editarArquivo(element)">
               edit
             </mat-icon>
             <mat-icon class="md-xs-16 ml-xs-0 mr-xs-auto" aria-hidden="false" title="Excluir o {{grupo}}"
               aria-label="Excluir" (click)="excluirArquivo(element)">
               delete_forever
             </mat-icon>
           </td>
         </ng-container>
         <tr mat-header-row *matHeaderRowDef="viewModel.displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: viewModel.displayedColumns;"></tr>
       </table>
     </div>
   </div>
