import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class JwtService {




  constructor(public httpClient: HttpClient,
    public router: Router) { }


  public logout() {
    localStorage.clear(); // removeItem('access_token');
    sessionStorage.clear();
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  public setToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  public get loggedIn(): boolean {
    return this.getToken() !== null && !this.isExpired;
  }
  public get decodedToken() {
    return helper.decodeToken(this.getToken());
  }
  public get expirationDate() {
    return helper.getTokenExpirationDate(this.getToken());
  }
  public get isExpired() {
    return helper.isTokenExpired(this.getToken());
  }

}
