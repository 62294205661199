<div [ngClass]="mostrarFiltrosPesquisa === true ? 'main-content pt-0 mt-5': ''" [formGroup]="form">
  <div [ngClass]="mostrarFiltrosPesquisa  === true ? 'container-fluid pt-1 mx-auto': ''">
    <div [ngClass]="mostrarFiltrosPesquisa  === true ? 'row': 'hide-form'">
      <input type="hidden" formControlName="uid" />
      <input type="hidden" formControlName="gid" />
      <input type="hidden" formControlName="chaveAtendimento" />
      <div class="col-12">
        <div class="card card-product">
          <div class="card-header card-header-info card-header-icon" data-header-animation="false">
            <div class="card-icon">
              <i class="material-icons">assignment</i>
            </div>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="menuFiltros">
              <mat-icon>bookmarks</mat-icon>
              <span>Filtros Prédefinidos</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="menuSort">
              <mat-icon>sort_by_alpha</mat-icon>
              <span>Ordenar Resultados</span>
            </button>
          </mat-menu>

          <mat-menu #menuFiltros="matMenu">
            <mat-list>
              <mat-action-list>
                <button mat-list-item [disabled]="form && form.status !=='VALID'"
                  [swal]="{ title: 'Salvar Filtro de Pesquisa',text: 'Defina um nome para o filtro.', input: 'text' }"
                  (confirm)="salvarFiltroPesquisa($event)">
                  <mat-icon matTooltip="Salvar Filtro">bookmark</mat-icon>
                  <span>Salvar Filtro</span>
                </button>
              </mat-action-list>
              <ng-container *ngIf="filtrosSalvos && filtrosSalvos.length>0">
                <mat-divider></mat-divider>
                <div mat-subheader>Filtros Salvos</div>
                <mat-action-list>
                  <mat-list-item *ngFor="let filtro of filtrosSalvos" class="listaFiltro">
                    <mat-panel-title>
                      <button (click)="carregarFiltros(filtro.parametros)">{{filtro.descricao}}</button>
                    </mat-panel-title>
                    <mat-card-actions>
                      <mat-icon mat-icon-button (click)="excluirFiltroPesquisa(filtro)">delete</mat-icon>
                      <mat-icon mat-icon-button (click)="atualizarFiltroPesquisa(filtro)">save_alt
                      </mat-icon>
                    </mat-card-actions>
                  </mat-list-item>
                </mat-action-list>
              </ng-container>
            </mat-list>
          </mat-menu>

          <mat-menu #menuSort="matMenu" [formGroup]="form">
            <mat-list>
              <mat-action-list>
                <mat-radio-group formControlName="sort" class="filtros-radio-group">
                  <mat-radio-button mat-list-item class="filtros-radio-button"
                    *ngFor="let sort of sortOptions; let i = index;" [value]="sort">
                    {{sort.title}}
                  </mat-radio-button>
                </mat-radio-group>
                <!-- <button mat-menu-item *ngFor="let sort of sortOptions"
                  (click)="filtros.sortDirection = sort.direction;filtros.sortField = sort.field;">{{sort.title}}</button> -->
              </mat-action-list>
            </mat-list>
          </mat-menu>
          <div class="row">
            <div class="col-12">
              <mat-accordion>
                <mat-expansion-panel #panelFiltros [expanded]="true" class="mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h4>Filtros de Pesquisa</h4>
                    </mat-panel-title>
                    <mat-card-actions>
                      <button *ngIf="(loading | async) === false" mat-icon-button [matMenuTriggerFor]="menu"
                        aria-label="Ações" (click)="$event.stopPropagation(); false;">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    </mat-card-actions>
                  </mat-expansion-panel-header>

                  <div class="row">
                    <div class="col-md-12">
                      <app-search-input placeholder="Pesquise o solicitante/requerente pelo CPF, RG, NIS ou nome"
                        [value]="filtros.consulta" formControlName="consulta" [searchOnChange]="false"
                        (search)="filtros.consulta = $event; pesquisarAtendimento()">
                      </app-search-input>
                    </div>
                  </div>
                  <div class="row mx-2">
                    <div class="col-md-6">
                      <mat-form-field [floatLabel]="true">
                        <mat-label>Tipo de atendimento realizado</mat-label>
                        <mat-select formControlName="tipos" multiple [compareWith]="compareByIdProperty">
                          <mat-option *ngFor="let item of (tiposAtendimento | async)" [value]="item.id">
                            {{item.descricao}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col-md-6 pl-2">
                      <mat-form-field [floatLabel]="true"
                        *ngIf="(equipamentos | async) !== null && (equipamentos | async)?.length >0">
                        <mat-label>Encaminhado para...</mat-label>
                        <mat-select formControlName="equipamentosEncaminhado" [disabled]="desabilitaEquipamentos"
                          multiple [compareWith]="compareByIdProperty">
                          <mat-option *ngFor="let item of (equipamentos | async)" [value]="item.id">{{item.descricao}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field [floatLabel]="true"
                        *ngIf="(profissionais | async) !== null && (profissionais | async).length >0">
                        <mat-label>
                          Atendimento por...
                        </mat-label>
                        <mat-select formControlName="profissionais" multiple [compareWith]="compareByIdProperty">
                          <mat-option *ngFor="let item of (profissionais | async)" [value]="item.id">{{item.descricao}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field [floatLabel]="true"
                        *ngIf="(profissionais | async) !== null && (profissionais | async).length >0">
                        <mat-label>
                          Agendado para...
                        </mat-label>
                        <mat-select [disabled]="desabilitaProfissionalAgendamento"
                          formControlName="profissionaisAgendamento" multiple [compareWith]="compareByIdProperty">
                          <mat-option *ngFor="let item of (profissionais | async)" [value]="item.id">{{item.descricao}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row mx-2">

                    <div class="col-md-6">
                      <mat-form-field [floatLabel]="true">
                        <mat-label>Situação do Atendimento</mat-label>
                        <mat-select formControlName="status" multiple>
                          <mat-option [value]="1">Aberto</mat-option>
                          <mat-option [value]="2">Em atendimento</mat-option>
                          <mat-option [value]="3">Encaminhado</mat-option>
                          <mat-option [value]="4">Agendado</mat-option>
                          <mat-option [value]="5">Não comparecimento</mat-option>
                          <mat-option [value]="6">Finalizado</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4 px-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Filtrar atendimentos entre as datas</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="De" formControlName="de">
                          <input matEndDate placeholder="Até" formControlName="ate">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
                    </div>

                    <div class="col-md-2">
                      <button mat-raised-button appearance="fill" class="warning pesquisar"
                        (click)="pesquisarAtendimento()">
                        <mat-icon>search</mat-icon>Pesquisar
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>

              </mat-accordion>
            </div>
          </div>

        </div>
      </div>
    </div>
    <app-loader *ngIf="loading | async"></app-loader>
    <div class="row" *ngIf="pesquisaCarregada | async">
      <div class="col-md-12" *ngIf="(resultadoPesquisaAtendimento | async) != null">
        <div class="card mt-0 mat-elevation-z0">
          <h4 class="warning p-2" *ngIf="(resultadoPesquisaAtendimento | async)?.model !== null">Resultado:
            {{(resultadoPesquisaAtendimento | async)?.model?.registros > 0 ? (resultadoPesquisaAtendimento | async)?.model?.registros + ' ficha(s) de atendimento encontrada(s)' :' Nenhuma ficha foi encontrada com os filtros informados.' }}

            <div *ngIf="(resultadoPesquisaAtendimento | async)?.model?.registros > 0" class="card-footer">
              <div class="ct-chart">
                <span
                  *ngFor="let item of tiposAtendimentoDistintos((resultadoPesquisaAtendimento | async)?.resultado); let i = index;"
                  [ngClass]="'mx-4 legend-ct-series-'+seriesIndexCss[item.cssIndex]">
                  <span><i ngClass="legend-color fa fa-circle"></i></span> <small>{{ item.tipo }}</small>
                </span>
              </div>
            </div>
          </h4>
          <mat-accordion>
            <mat-expansion-panel *ngFor="let item of (resultadoPesquisaAtendimento | async).resultado"
              (opened)="abrirAtendimentoDetalhado(item)"
              [expanded]="(resultadoPesquisaAtendimento | async)?.model?.registros === 1">
              <mat-expansion-panel-header ngClass="ct-chart">
                <mat-panel-title>
                  <button mat-icon-button (click)="abrirProtocoloAtendimento(item)">
                    <mat-icon>qr_code_2</mat-icon>
                  </button> Nº <strong class="mx-2"> {{item.codigo}} </strong> ({{ item.status }})

                </mat-panel-title>
                <mat-panel-description>
                  Abertura: {{item.abertura | date:'dd/MM/yyyy'}} - Solic.: <strong class="mx-2">
                    {{item.nomeSocial ? item.nomeSocial : item.nome}}</strong>

                  <mat-chip-list aria-label="Tipos de Atendimento" *ngFor="let tipo of item.tiposAtendimento.split(',')"
                    [ngClass]="'legend-ct-series-'+ seriesIndexCss[this.tiposAtendimentoDistintosMap[tipo.split('|')[0]].cssIndex]">
                    <mat-chip [matTooltip]="tipo.replace('|', ' » ')" ngClass="small-chip chip-color" selected>
                      {{tipo.split('|')[1]}}</mat-chip>
                  </mat-chip-list>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div *ngIf="mostrarDadosIndividuo" class="row m-3">

                <div class="col-md-3 col-12">
                  <img class="image img-thumbnail hidden-xs w-100"
                    [src]="item.foto || './assets/img/default-avatar.png'">
                </div>
                <div class="col-md-9 bg-rose align-text-top">
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field [floatLabel]="true">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" placeholder="Nome"
                          value="{{item.nomeSocial && item.nomeSocial || item.nome}}" disabled>
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field [floatLabel]="true">
                        <mat-label>CPF</mat-label>
                        <input matInput type="text" placeholder="CPF" value="{{item.cpf}}" disabled>
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field [floatLabel]="true">
                        <mat-label>Nascimento</mat-label>
                        <input matInput type="text" placeholder="Nascimento"
                          value="{{item.nascimento | date:'dd/MM/yyyy'}} - {{item.idade}}" disabled>
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field [floatLabel]="true">
                        <mat-label>Endereço</mat-label>
                        <input matInput type="text" placeholder="Endereço" value="{{item.endereco}}" disabled>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-3" *ngIf="item.detalhado">
                <div class="col-md-6 col-12">
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field [floatLabel]="true">
                        <mat-label>Abertura da Ficha</mat-label>
                        <input matInput type="text" placeholder="Nome"
                          value="{{item.abertura | date:'dd/MM/yyyy HH:mm'}}" disabled>
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field [floatLabel]="true">
                        <mat-label>Data do último andamento</mat-label>
                        <input matInput type="text" placeholder="Nome"
                          value="{{item.abertura | date:'dd/MM/yyyy HH:mm'}}" disabled>
                      </mat-form-field>
                    </div>

                  </div>
                </div>
                <div class="col-md-6 align-text-top">
                  <div class="row">
                    <div class="col-md-12">
                      <mat-form-field [floatLabel]="true">
                        <mat-label>Parentesco</mat-label>
                        <input matInput type="text" placeholder="Nome"
                          value="{{item.detalhado?.ficha?.requerente?.parentesco}}" disabled>
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field [floatLabel]="true">
                        <mat-label>Atendido por</mat-label>
                        <input matInput type="text" placeholder="Atendido por" value="{{item.responsavelTecnico}}"
                          disabled>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <table mat-table [dataSource]="item.detalhado.ficha.registros" class="full mat-elevation-z1">
                    <ng-container matColumnDef="dataRegistro">
                      <th mat-header-cell align="left" *matHeaderCellDef> Data </th>
                      <td mat-cell align="left" *matCellDef="let element"> {{element.dataRegistro | date:'dd/MM/yyyy'}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="grupoAtendimento">
                      <th mat-header-cell align="left" *matHeaderCellDef> Grupo/Tipo </th>
                      <td mat-cell align="left" *matCellDef="let element"> {{element.grupoAtendimento}} /
                        {{element.tipoAtendimento}} </td>
                    </ng-container>
                    <ng-container matColumnDef="tipoAtendimento">
                      <th mat-header-cell align="left" *matHeaderCellDef> Detalhamento
                      </th>
                      <td mat-cell align="left" *matCellDef="let element">
                        <ng-container *ngIf="element.encaminhamento">
                          {{element.encaminhamento.descricao}}
                        </ng-container>
                        <ng-container *ngIf="element.beneficios">
                          {{beneficiosDescricao(element.beneficios)}}
                        </ng-container>
                        <ng-container *ngIf="element.agendaTecnico">
                          {{element.agendaTecnico}}
                        </ng-container>
                        <ng-container *ngIf="element.responsavelTecnico">
                          {{element.responsavelTecnico}}
                        </ng-container>
                        <ng-container *ngIf="element.observacao">
                          ({{element.observacao}})
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell align="left" *matHeaderCellDef> Andamento </th>
                      <td mat-cell align="left" *matCellDef="let element"> {{element.status}} </td>
                    </ng-container>

                    <ng-container matColumnDef="registroAtendimentoId">
                      <th mat-header-cell align="left" *matHeaderCellDef> Ações </th>
                      <td mat-cell align="left" *matCellDef="let element">
                        <swal #parecer title="Parecer Técnico" icon="info" width="95%">
                          <div *swalPortal class="portalSwalParecer">
                            <div style="width:100%; max-height: 300px; overflow-y: auto;">
                              <ng-container *ngFor="let parecer of element.parecer">
                                <h6 class="col-md-12 btn btn-success success">Data de registro:
                                  {{parecer.data | date:"dd/MM/yyyy"}}</h6>
                                <div class="mt-2">
                                  <mat-card-header class="card-container">
                                    <mat-form-field appearance="fill">
                                      <mat-label>
                                        <mat-icon>lock</mat-icon>
                                      </mat-label>
                                      <mat-select [disabled]="true" class="superZ">
                                        <mat-option *ngFor="let sigilo of sigilos" [value]="sigilo.id">{{sigilo.nivel}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </mat-card-header>
                                  <mat-card-content>
                                    <mat-form-field appearance="fill">
                                      <mat-label>Comentário Público</mat-label>
                                      <textarea matInput class="parecer" disabled>{{element.observacao}}</textarea>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                      <mat-label>Parecer Técnico desse atendimento</mat-label>
                                      <textarea matInput class="parecer" disabled>{{parecer.parecer}}</textarea>
                                    </mat-form-field>
                                  </mat-card-content>
                                </div>
                              </ng-container>
                            </div>

                          </div>
                        </swal>

                        <swal #novoParecer title="Alterar Registro de Atendimento Técnico" icon="info" width="95%"
                          (beforeOpen)="formNovoParecer.patchValue({
                            sigilo: element.parecer[element.parecer.length-1].nivelSigiloId,
                            status: element.statusId,
                            parecer:  element.parecer[element.parecer.length-1].parecer})" [showCancelButton]="true"
                          cancelButtonText="Cancelar Inserção" confirmButtonText="Inserir Parecer"
                          [showConfirmButton]="true"
                          (confirm)="modificarRegistroAtendimento($event, item, element.registroAtendimentoId)">
                          <div *swalPortal class="portalSwalParecer">
                            <div [formGroup]="formNovoParecer" style="width:100%; max-height:300px; overflow-y: auto;">

                              <div class="mt-2">

                                <mat-card-content>
                                  <mat-form-field appearance="fill">
                                    <mat-label>Comentário Público</mat-label>
                                    <textarea matInput class="parecer" formControlName="observacao"
                                      rows="2">{{element.observacao}} </textarea>
                                  </mat-form-field>
                                  <mat-form-field appearance="fill">
                                    <mat-label matTooltip="Nível de sigilo do parecer técnico">
                                      <mat-icon>lock</mat-icon>
                                    </mat-label>
                                    <select matNativeControl #sigilo class="superZ" formControlName="sigilo">
                                      <option *ngFor="let sigilo of sigilos" [value]="sigilo.id">{{sigilo.nivel}}
                                      </option>
                                    </select>
                                  </mat-form-field>
                                  <mat-form-field appearance="fill">
                                    <mat-label>Novo Parecer Técnico desse atendimento</mat-label>
                                    <textarea matInput class="parecer" formControlName="parecer"
                                      rows="6">{{element.parecer?.parecer || element.parecer?.comentarioPublico}} </textarea>
                                  </mat-form-field>

                                  <mat-form-field appearance="fill">
                                    <mat-label>Estado final do Atendimento</mat-label>
                                    <select matNativeControl #sigilo class="superZ" formControlName="status">
                                      <option *ngFor="let status of novoStatus" [value]="status.id">{{status.descricao}}
                                      </option>
                                    </select>
                                  </mat-form-field>
                                </mat-card-content>
                              </div>

                            </div>
                          </div>
                        </swal>

                        <button *ngIf="element.parecer && element.parecer.length>0" mat-icon-button [swal]="parecer">
                          <mat-icon>notes</mat-icon>
                        </button>
                        <button mat-button [swal]="novoParecer">
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button *ngIf="item?.detalhado?.permiteAbertura === true"
                          (click)="novoRegistro(item?.detalhado?.ficha)">
                          <mat-icon matTooltip="Adicionar Novo Registro no Atendimento">assignment</mat-icon>
                        </button>
                      </td>
                    </ng-container>
                    <tr mat-header-row
                      *matHeaderRowDef="['dataRegistro','status', 'grupoAtendimento','tipoAtendimento','registroAtendimentoId']">
                    </tr>
                    <tr mat-row
                      *matRowDef="let row; columns: ['dataRegistro','status', 'grupoAtendimento','tipoAtendimento','registroAtendimentoId'];">
                    </tr>
                  </table>
                </div>

              </div>

            </mat-expansion-panel>
          </mat-accordion>
          <div class="row mt-2">
            <div class="col-md-12">
              <mat-paginator [length]="pageEvent.length" [pageIndex]="pageEvent.pageIndex"
                [pageSize]="pageEvent.pageSize" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent = $event;pesquisarAtendimento();">
              </mat-paginator>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- TODO: exportar -->
